"use client"

import * as React from "react"
import { Check, ChevronDown } from "lucide-react"
import { cn } from "../lib/utils"
import { Button } from "../components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover"

const genres = [
  // Moderate content genres
    { value: "affair", label: "Affair", level: "moderate" },
    { value: "alien", label: "Alien", level: "moderate" },
    { value: "alpha-protector", label: "Alpha Protector", level: "moderate" },
    { value: "anynomous-stranger", label: "Anonymous Stranger", level: "moderate" },
    { value: "bar", label: "Bar", level: "moderate" },
    { value: "beach", label: "Beach", level: "moderate" },
    { value: "bi-curious", label: "Bi-curious", level: "moderate" },
    { value: "billionaire-romance", label: "Billionaire Romance", level: "moderate" },
    { value: "blind-date", label: "Blind Date", level: "moderate" },
    { value: "boss-teacher", label: "Boss or Teacher", level: "moderate" },
    { value: "cruise-ship", label: "Cruise Ship", level: "moderate" },
    { value: "dating-app", label: "Dating App", level: "moderate" },
    { value: "dominance", label: "Dominance", level: "moderate" },
    { value: "dominant-lover", label: "Dominant Lover", level: "moderate" },
    { value: "emotional-build-up", label: "Emotional Build Up", level: "moderate" },
    { value: "empowerment", label: "Empowerment", level: "moderate" },
    { value: "fantasy-world", label: "Fantasy World", level: "moderate" },
    { value: "fetish", label: "Fetish", level: "moderate" },
    { value: "first-date", label: "First Date", level: "moderate" },
    { value: "forest", label: "Forest", level: "moderate" },
    { value: "friends-with-benefits", label: "Friends with Benefits", level: "moderate" },
    { value: "gay", label: "Gay", level: "moderate" },
    { value: "high-school-crush", label: "High School Crush", level: "moderate" },
    { value: "historical-setting", label: "Historical Setting", level: "moderate" },
    { value: "hostage", label: "Hostage", level: "moderate" },
    { value: "luxury-hotel", label: "Luxury Hotel", level: "moderate" },
    { value: "masquerade-party", label: "Masquerade Party", level: "moderate" },
    { value: "medival", label: "Medival", level: "moderate" },
    { value: "night-out", label: "Night Out", level: "moderate" },
    { value: "neigbor", label: "Neighbor", level: "moderate" },
    { value: "office-party", label: "Office Party", level: "moderate" },
    { value: "office-romance", label: "Office Romance", level: "moderate" },
    { value: "opposites-attract", label: "Opposites Attract", level: "moderate" },
    { value: "outdoors", label: "Outdoors", level: "moderate" },
    { value: "pool-party", label: "Pool Party", level: "moderate" },
    { value: "power-dynamics", label: "Power Dynamics", level: "moderate" },
    { value: "private-yacht", label: "Private Yacht", level: "moderate" },
    { value: "romantic", label: "Romantic", level: "moderate" },
    { value: "romantic-dinner", label: "Romantic Dinner", level: "moderate" },
    { value: "royal-seduction", label: "Royal Seduction", level: "moderate" },
    { value: "sci-fi", label: "Sci-Fi", level: "moderate" },
    { value: "straight", label: "Straight", level: "moderate" },
    { value: "submission", label: "Submission", level: "moderate" },
    { value: "summer-party", label: "Summer Party", level: "moderate" },
    { value: "time-travel", label: "Time Travel", level: "moderate" },
    { value: "trapped-together", label: "Trapped Together", level: "moderate" },
    { value: "tradesperson", label: "Tradesperson", level: "moderate" },
    { value: "vacation", label: "Vacation", level: "moderate" },
    { value: "wedding", label: "Wedding", level: "moderate" },
  
  // Explicit content genres
  { value: "adult-toy-store", label: "Adult Toy Store", level: "explicit" },
  { value: "adult-theatre", label: "Adult Theatre", level: "explicit" },
  { value: "affair", label: "Affair", level: "explicit" },
  { value: "anal-beads", label: "Anal Beads", level: "explicit" },
  { value: "anal-creampie", label: "Anal Creampie", level: "explicit" },
  { value: "anal-orgasm", label: "Anal Orgasm", level: "explicit" },
  { value: "anonymous", label: "Anonymous", level: "explicit" },
  { value: "alien", label: "Alien", level: "explicit" },
  { value: "alien-abduction", label: "Alien Abduction", level: "explicit" },
  { value: "airplane-sex", label: "Airplane Sex", level: "explicit" },
  { value: "asian", label: "Asian", level: "explicit" },
  { value: "ass-to-mouth", label: "Ass to Mouth", level: "explicit" },
  { value: "athletic", label: "Athletic", level: "explicit" },
  { value: "aunt", label: "Aunt", level: "explicit" },
  { value: "babysitter", label: "Babysitter", level: "explicit" },
  { value: "bar", label: "Bar", level: "explicit" },
  { value: "bath-house", label: "Bath House", level: "explicit" },
  { value: "bathroom", label: "Bathroom", level: "explicit" },
  { value: "bdsm", label: "BDSM", level: "explicit" },
  { value: "bisexual", label: "Bisexual", level: "explicit" },
  { value: "body-swap", label: "Body Swap", level: "explicit" },
  { value: "body-aroma", label: "Body Aroma", level: "explicit" },
  { value: "boss", label: "Boss", level: "explicit" },
  { value: "bi-cuckold", label: "Bi Cuckold", level: "explicit" },
  { value: "big-black-cock", label: "Big Black Cock (BBC)", level: "explicit" },
  { value: "blonde", label: "Blonde", level: "explicit" },
  { value: "brunette", label: "Brunette", level: "explicit" },
  { value: "bukkake", label: "Bukkake", level: "explicit" },
  { value: "bull-fucks-cuckold-husband", label: "Bull Fucks Cuckold Husband", level: "explicit" },
  { value: "business-trip", label: "Business Trip", level: "explicit" },
  { value: "buttplug", label: "Buttplug", level: "explicit" },
  { value: "buttplug-in-public", label: "Buttplug in Public", level: "explicit" },
  { value: "brother-in-law", label: "Brother-in-Law", level: "explicit" },
  { value: "car-sex", label: "Car Sex", level: "explicit" },
  { value: "caught", label: "Caught", level: "explicit" },
  { value: "caught-in-public", label: "Caught in Public", level: "explicit" },
  { value: "caught-cuckolding", label: "Caught Cuckolding", level: "explicit" },
  { value: "cheating", label: "Cheating", level: "explicit" },
  { value: "chastity-cage", label: "Chastity Cage", level: "explicit" },
  { value: "church", label: "Church", level: "explicit" },
  { value: "creampie", label: "Creampie", level: "explicit" },
  { value: "college", label: "College", level: "explicit" },
  { value: "college-dorm", label: "College Dorm", level: "explicit" },
  { value: "co-worker", label: "Co-worker", level: "explicit" },
  { value: "cuckold", label: "Cuckold", level: "explicit" },
  { value: "cuckquean", label: "Cuckquean", level: "explicit" },
  { value: "dance-club", label: "Dance Club", level: "explicit" },
  { value: "dating-app", label: "Dating App", level: "explicit" },
  { value: "degrading", label: "Degrading", level: "explicit" },
  { value: "double-dildo", label: "Double Dildo", level: "explicit" },
  { value: "dildo", label: "Dildo", level: "explicit" },
  { value: "dirty", label: "Dirty", level: "explicit" },
  { value: "dominance", label: "Dominance", level: "explicit" },
  { value: "drinking", label: "Drinking", level: "explicit" },
  { value: "elevator", label: "Elevator", level: "explicit" },
  { value: "exchange-student", label: "Exchange Student", level: "explicit" },
  { value: "fairy-tale", label: "Fairy Tale", level: "explicit" },
  { value: "femboy", label: "Femboy", level: "explicit" },
  { value: "femdom", label: "Femdom", level: "explicit" },
  { value: "football", label: "Football", level: "explicit" },
  { value: "football-team", label: "Football Team", level: "explicit" },
  { value: "foot-fetish", label: "Foot Fetish", level: "explicit" },
  { value: "fucklick", label: "Fucklick", level: "explicit" },
  { value: "furry", label: "Furry", level: "explicit" },
  { value: "futanari", label: "Futanari", level: "explicit" },
  { value: "futuristic", label: "Futuristic", level: "explicit" },
  { value: "gaping-asshole", label: "Gaping Asshole", level: "explicit" },
  { value: "gaping-pussy", label: "Gaping Pussy", level: "explicit" },
  { value: "gay", label: "Gay", level: "explicit" },
  { value: "gay-club", label: "Gay Club", level: "explicit" },
  { value: "giant-ass", label: "Giant Ass", level: "explicit" },
  { value: "giant-asshole", label: "Giant Asshole", level: "explicit" },
  { value: "giant-boobs", label: "Giant Boobs", level: "explicit" },
  { value: "giant-cock", label: "Giant Cock", level: "explicit" },
  { value: "giant-dildo", label: "Giant Dildo", level: "explicit" },
  { value: "gloryhole", label: "Gloryhole", level: "explicit" },
  { value: "hairy", label: "Hairy", level: "explicit" },
  { value: "harem", label: "Harem", level: "explicit" },
  { value: "hotel", label: "Hotel", level: "explicit" },
  { value: "hotel-bar", label: "Hotel Bar", level: "explicit" },
  { value: "hot-wax", label: "Hot Wax", level: "explicit" },
  { value: "hotwife", label: "Hotwife", level: "explicit" },
  { value: "humilation", label: "Humilation", level: "explicit" },
  { value: "husband", label: "Husband", level: "explicit" },
  { value: "husband-agrees-to-threesome", label: "Husband Agrees to Threesome", level: "explicit" },
  { value: "husbands-friend", label: "Husbands Friend", level: "explicit" },
  { value: "husband-sucks-bull", label: "Husband Sucks Bull", level: "explicit" },
  { value: "husband-pleasures-bull", label: "Husband Pleasures Bull", level: "explicit" },
  { value: "in-nature", label: "In Nature", level: "explicit" },
  { value: "international-student", label: "International Student", level: "explicit" },
  { value: "invisibility", label: "Invisibility", level: "explicit" },
  { value: "jock", label: "Jock", level: "explicit" },
  { value: "kinky", label: "Kinky", level: "explicit" },
  { value: "leather", label: "Leather", level: "explicit" },
  { value: "lesbian-club", label: "Lesbian Club", level: "explicit" },
  { value: "library", label: "Library", level: "explicit" },
  { value: "lifestyle", label: "Lifestyle", level: "explicit" },
  { value: "lingerie", label: "Lingerie", level: "explicit" },
  { value: "love", label: "Love", level: "explicit" },
  { value: "love-hotel", label: "Love Hotel", level: "explicit" },
  { value: "loving", label: "Loving", level: "explicit" },
  { value: "lover", label: "Lover", level: "explicit" },
  { value: "loud-screaming", label: "Loud Screaming", level: "explicit" },
  { value: "nudism", label: "Nudism", level: "explicit" },
  { value: "massage-parlor", label: "Massage Parlor", level: "explicit" },
  { value: "medical", label: "Medical", level: "explicit" },
  { value: "medival", label: "Medival", level: "explicit" },
  { value: "milf", label: "Milf", level: "explicit" },
  { value: "military", label: "Military", level: "explicit" },
  { value: "mind-control", label: "Mind Control", level: "explicit" },
  { value: "mistress", label: "Mistress", level: "explicit" },
  { value: "muscular", label: "Muscular", level: "explicit" },
  { value: "mutual-dildo", label: "Mutual Dildo", level: "explicit" },
  { value: "old-and-young", label: "Old and Young", level: "explicit" },
  { value: "orgy", label: "Orgy", level: "explicit" },
  { value: "paranormal", label: "Paranormal", level: "explicit" },
  { value: "photoshoot", label: "Photoshoot", level: "explicit" },
  { value: "power-dynamics", label: "Power Dynamics", level: "explicit" },
  { value: "pool-party", label: "Pool Party", level: "explicit" },
  { value: "polyamory", label: "Polyamory", level: "explicit" },
  { value: "polygamy", label: "Polygamy", level: "explicit" },
  { value: "professor", label: "Professor", level: "explicit" },
  { value: "prostate-orgasm", label: "Prostate Orgasm", level: "explicit" },
  { value: "pubic-hair", label: "Pubic Hair", level: "explicit" },
  { value: "pulsating-ass", label: "Pulsating Ass", level: "explicit" },
  { value: "pulsating-cock", label: "Pulsating Cock", level: "explicit" },
  { value: "pulsating-pussy", label: "Pulsating Pussy", level: "explicit" },
  { value: "reality-tv-show", label: "Reality TV Show", level: "explicit" },
  { value: "rough", label: "Rough", level: "explicit" },
  { value: "same-room-sex", label: "Same-room Sex", level: "explicit" },
  { value: "sauna", label: "Sauna", level: "explicit" },
  { value: "science-lab", label: "Science Lab", level: "explicit" },
  { value: "sex-club", label: "Sex Club", level: "explicit" },
  { value: "sex-in-public", label: "Sex in Public", level: "explicit" },
  { value: "sex-therapy", label: "Sex Therapy", level: "explicit" },
  { value: "sex-swing", label: "Sex Swing", level: "explicit" },
  { value: "sex-train-of-persons", label: "Sex Train of Persons", level: "explicit" },
  { value: "sex-on-a-train", label: "Sex on a Train", level: "explicit" },
  { value: "sex-worker", label: "Sex Worker", level: "explicit" },
  { value: "sci-fi", label: "Sci-Fi", level: "explicit" },
  { value: "sharing-boyfriend", label: "Sharing Boyfriend", level: "explicit" },
  { value: "sharing-girlfriend", label: "Sharing Girlfriend", level: "explicit" },
  { value: "sharing-husband", label: "Sharing Husband", level: "explicit" },
  { value: "sharing-wife", label: "Sharing Wife", level: "explicit" },
  { value: "shower-sex", label: "Shower Sex", level: "explicit" },
  { value: "sissigasm", label: "Sissigasm", level: "explicit" },
  { value: "spaceship", label: "Spaceship", level: "explicit" },
  { value: "strip-club", label: "Strip Club", level: "explicit" },
  { value: "stepmother", label: "Stepmother", level: "explicit" },
  { value: "stepfather", label: "Stepfather", level: "explicit" },
  { value: "stepsister", label: "Stepsister", level: "explicit" },
  { value: "stepbrother", label: "Stepbrother", level: "explicit" },
  { value: "strap-on", label: "Strap-on", level: "explicit" },
  { value: "student-and-teacher", label: "Student and Teacher", level: "explicit" },
  { value: "submissive", label: "Submissive", level: "explicit" },
  { value: "sister-in-law", label: "Sister-in-Law", level: "explicit" },
  { value: "sissy", label: "Sissy", level: "explicit" },
  { value: "slumber-party", label: "Slumber Party", level: "explicit" },
  { value: "spanking", label: "Spanking", level: "explicit" },
  { value: "swinger-lifestyle", label: "Swinger (Lifestyle)", level: "explicit" },
  { value: "swinger-club", label: "Swinger Club", level: "explicit" },
  { value: "swinger-house", label: "Swinger House", level: "explicit" },
  { value: "swinger-party", label: "Swinger Party", level: "explicit" },
  { value: "sweet-delicate-love", label: "Sweet Delicate Love", level: "explicit" },
  { value: "sweaty", label: "Sweaty", level: "explicit" },
  { value: "taboo", label: "Taboo", level: "explicit" },
  { value: "teasing", label: "Teasing", level: "explicit" },
  { value: "teacher", label: "Teacher", level: "explicit" },
  { value: "tied-up", label: "Tied Up", level: "explicit" },
  { value: "time-travel", label: "Time Travel", level: "explicit" },
  { value: "travel", label: "Travel", level: "explicit" },
  { value: "twink", label: "Twink", level: "explicit" },
  { value: "two-sided-dildo", label: "Two-sided Dildo", level: "explicit" },
  { value: "ufo", label: "UFO", level: "explicit" },
  { value: "virgin", label: "Virgin", level: "explicit" },
  { value: "vibrator", label: "Vibrator", level: "explicit" },
  { value: "virgin-ass", label: "Virgin Ass", level: "explicit" },
  { value: "virtual-reality", label: "Virtual Reality", level: "explicit" },
  { value: "vixen-and-stag", label: "Vixen and Stag", level: "explicit" },
  { value: "voyeurism", label: "Voyeurism", level: "explicit" },
  { value: "workplace", label: "Workplace", level: "explicit" },
  { value: "wife", label: "Wife", level: "explicit" },
  { value: "wife-agrees-to-threesome", label: "Wife Agrees to Threesome", level: "explicit" },
  { value: "wife-watches-husband-fuck", label: "Wife Watches Husband Fuck", level: "explicit" },
  { value: "wife-watches-husband-get-fucked", label: "Wife Watches Husband Get Fucked", level: "explicit" },
  { value: "wifes-girlfriend", label: "Wifes Girlfriend", level: "explicit" },
  { value: "wife-swap", label: "Wife Swap", level: "explicit" },
  { value: "with-condom", label: "With Condom", level: "explicit" },
  { value: "without-condom", label: "Without Condom", level: "explicit" },
  { value: "watching-others", label: "Watching Others", level: "explicit" },
  { value: "yoga", label: "Yoga", level: "explicit" },
  { value: "zero-gravity", label: "Zero Gravity", level: "explicit" },
] as const

interface GenreComboboxProps {
  value: string[]
  onChange: (value: string[]) => void
  contentLevel: "moderate" | "explicit"
}

export function GenreCombobox({ value, onChange, contentLevel }: GenreComboboxProps) {
  const [open, setOpen] = React.useState(false)

  const filteredGenres = genres.filter((genre) => genre.level === contentLevel)
  const selectedGenres = filteredGenres.filter((genre) => value.includes(genre.value))

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex h-10 w-full items-center justify-between rounded-md border border-gray-400 bg-black text-white px-3 py-2 text-sm ring-offset-background placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
        >
          <span style={{ pointerEvents: "none" }}>
            {selectedGenres.length > 0
              ? selectedGenres.map((genre) => genre.label).join(", ")
              : "Select theme"}
          </span>
          <ChevronDown className="h-4 w-4 opacity-50 text-gray-400" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0 bg-black border border-gray-400">
        <Command className="bg-black">
          <CommandInput placeholder="Search themes..." className="h-9 bg-black text-white" />
          <CommandEmpty className="text-gray-400">Nothing found.</CommandEmpty>
          <CommandGroup className="max-h-[300px] overflow-y-auto">
            {filteredGenres.map((genre) => (
              <CommandItem
                key={genre.value}
                value={genre.value}
                onSelect={() => {
                  onChange(
                    value.includes(genre.value)
                      ? value.filter((v) => v !== genre.value)
                      : [...value, genre.value]
                  )
                }}
                className="text-white hover:bg-gray-800 aria-selected:bg-gray-800"
              >
                <div className="flex items-center gap-2">
                  <div className="border border-gray-400 rounded w-4 h-4 flex items-center justify-center">
                    {value.includes(genre.value) && (
                      <Check className="h-3 w-3" />
                    )}
                  </div>
                  {genre.label}
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
