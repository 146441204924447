import React from 'react';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Checkbox } from "../components/ui/checkbox";
import { PlusCircle, Trash2, X } from "lucide-react";
import type { StoryGeneration } from "@/../../types/schema";
import { MAX_LENGTHS, AI_KEYWORDS, PROMPT_INJECTION_PATTERNS } from '../lib/validation';
import { useToast } from "../hooks/use-toast";

interface Character {
  name: string;
  gender: string;
  appearance?: string;
  personality?: string[];
  backgroundStory?: string;
}

interface CharacterCustomizationProps {
  value: Partial<StoryGeneration>;
  onChange: (data: Partial<StoryGeneration>) => void;
}

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non-binary", label: "Non-binary" },
  { value: "transgender-ftm", label: "Transgender FtM" },
  { value: "transgender-mtf", label: "Transgender MtF" }
];

const personalityTraits = [
  { value: "adventurous", label: "Adventurous" },
  { value: "aggressive", label: "Aggressive" },
  { value: "alpha", label: "Alpha" },
  { value: "charismatic", label: "Charismatic" },
  { value: "confident", label: "Confident" },
  { value: "curious", label: "Curious" },
  { value: "dominant", label: "Dominant" },
  { value: "impulsive", label: "Impulsive" },
  { value: "innocent", label: "Innocent" },
  { value: "introverted", label: "Introverted" },
  { value: "jealous", label: "Jealous" },
  { value: "mysterious", label: "Mysterious" },
  { value: "nurturing", label: "Nurturing" },
  { value: "passive", label: "Passive" },
  { value: "playful", label: "Playful" },
  { value: "seductive", label: "Seductive" },
  { value: "shy", label: "Shy" },
  { value: "spontaneous", label: "Spontaneous" },
  { value: "submissive", label: "Submissive" },
];

export default function CharacterCustomization({
  value,
  onChange,
}: CharacterCustomizationProps) {
  const characters = (value.characters || []) as Character[];
  const { toast } = useToast();

  const addCharacter = () => {
    onChange({
      ...value,
      characters: [
        ...characters,
        { 
          name: "", 
          gender: "",
          appearance: "",
          personality: [],
          backgroundStory: ""
        },
      ],
    });
  };

  const updateCharacter = (index: number, field: keyof Character, fieldValue: any) => {
    const updatedCharacters = [...characters];  
    const currentChar = updatedCharacters[index] || {};
    
    // For text fields that need validation
    if (field === 'appearance' || field === 'backgroundStory') {
      const maxLength = field === 'appearance' ? MAX_LENGTHS.CHARACTER_APPEARANCE : MAX_LENGTHS.CHARACTER_BACKGROUND;
      // Only check for AI prompts and length, preserve spaces
      if (fieldValue.length > maxLength || 
          AI_KEYWORDS.some(keyword => fieldValue.toLowerCase().includes(keyword.toLowerCase())) ||
          PROMPT_INJECTION_PATTERNS.some(pattern => pattern.test(fieldValue))) {
        toast({
          title: "Invalid Input",
          description: `Please check your input. Maximum ${maxLength} characters allowed. No AI instructions or special formatting.`,
          variant: "destructive"
        });
        return;
      }
    }

    updatedCharacters[index] = {
      ...currentChar,
      [field]: fieldValue
    };

    onChange({
      ...value,
      characters: updatedCharacters
    });
  };

  const togglePersonalityTrait = (index: number, trait: string) => {
    const character = characters[index];
    const traits = character.personality || [];
    const newTraits = traits.includes(trait)
      ? traits.filter(t => t !== trait)
      : [...traits, trait];
    updateCharacter(index, 'personality', newTraits);
  };

  const removeCharacter = (index: number) => {
    const newCharacters = characters.filter((_: any, i: number) => i !== index);
    onChange({ ...value, characters: newCharacters });
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold mb-4 text-white">Create Your Characters</h2>
        <p className="text-gray-400 mb-6">
          Define the characters that will appear in your story.
        </p>
      </div>

      <div className="space-y-4">
        {characters.map((character, index) => (
          <div
            key={index}
            className="p-4 bg-black border-gray-800"
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold text-white">Character {index + 1}</h3>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => removeCharacter(index)}
                className="text-gray-400 hover:text-red-500"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor={`name-${index}`} className="text-white">
                    Name <span className="text-red-500">*</span>
                  </Label>
                  <Input
                    id={`name-${index}`}
                    value={character.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > MAX_LENGTHS.CHARACTER_NAME || 
                          AI_KEYWORDS.some(keyword => value.toLowerCase().includes(keyword.toLowerCase())) ||
                          PROMPT_INJECTION_PATTERNS.some(pattern => pattern.test(value))) {
                        toast({
                          title: "Invalid Input",
                          description: `Character name must be less than ${MAX_LENGTHS.CHARACTER_NAME} characters and contain no special formatting.`,
                          variant: "destructive"
                        });
                        return;
                      }
                      updateCharacter(index, 'name', value);
                    }}
                    className="bg-gray-800 text-white border-gray-700"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor={`gender-${index}`} className="text-white">
                    Gender <span className="text-red-500">*</span>
                  </Label>
                  <Select
                    value={character.gender}
                    onValueChange={(value) =>
                      updateCharacter(index, 'gender', value)
                    }
                  >
                    <SelectTrigger id={`gender-${index}`}>
                      <SelectValue placeholder="Select gender" />
                    </SelectTrigger>
                    <SelectContent>
                      {genderOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor={`appearance-${index}`} className="text-white">Appearance</Label>
                <Input
                  id={`appearance-${index}`}
                  value={character.appearance}
                  onChange={(e) => {
                    updateCharacter(index, 'appearance', e.target.value);
                  }}
                  maxLength={MAX_LENGTHS.CHARACTER_APPEARANCE}
                  placeholder="e.g., 28yr old, blonde, blue eyes"
                />
              </div>
              <div className="space-y-2">
                <Label className="text-white">Personality Traits</Label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                  {personalityTraits.map((trait) => (
                    <div key={trait.value} className="flex items-center space-x-2">
                      <Checkbox
                        id={`${trait.value}-${index}`}
                        checked={(character.personality || []).includes(trait.value)}
                        onCheckedChange={() => togglePersonalityTrait(index, trait.value)}
                      />
                      <label
                        htmlFor={`${trait.value}-${index}`}
                        className="text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {trait.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor={`background-${index}`} className="text-white">Character's Background Story</Label>
                <Textarea
                  id={`background-${index}`}
                  value={character.backgroundStory}
                  onChange={(e) => {
                    updateCharacter(index, 'backgroundStory', e.target.value);
                  }}
                  maxLength={MAX_LENGTHS.CHARACTER_BACKGROUND}
                  placeholder="Enter character's background story"
                  className="min-h-[100px]"
                />
              </div>
            </div>
          </div>
        ))}
        <Button
          onClick={addCharacter}
          className="w-full bg-red-500 hover:bg-red-600 text-white"
        >
          <PlusCircle className="h-4 w-4 mr-2" />
          Add Character
        </Button>
      </div>
    </div>
  );
}
