import React from 'react';
import { Link } from 'wouter';
import { Header } from '@/components/app/Header';
import Footer from '@/components/app/Footer';
import { ChevronRight, BookOpen, Share2, Users, Settings, HelpCircle } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { SEO } from '@/components/SEO';

const supportArticles = [
  {
    title: "How to Generate Stories",
    description: "Learn how to use our AI-powered story generation tools effectively.",
    icon: BookOpen,
    href: "/support/how-to-generate-stories"
  },
  {
    title: "Sharing Your Stories",
    description: "Tips and guides for sharing your stories and building an audience.",
    icon: Share2,
    href: "/support/story-sharing"
  },
  {
    title: "Community Guidelines",
    description: "Understanding our community standards and best practices.",
    icon: Users,
    href: "/support/community-guidelines"
  },
  {
    title: "Account Settings",
    description: "Managing your account, privacy, and security settings.",
    icon: Settings,
    href: "/support/account-settings"
  },
  {
    title: "Troubleshooting",
    description: "Solutions to common issues and technical problems.",
    icon: HelpCircle,
    href: "/support/troubleshooting"
  }
];

const SupportPage = () => {
  const { toast } = useToast();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // In a real app, this would send the form data to your backend
    toast({
      title: "Message Sent",
      description: "We'll get back to you as soon as possible.",
    });
    (e.target as HTMLFormElement).reset();
  };

  return (
    <>
      <SEO 
        title="Support Center"
        description="Get help with StoryCraft. Find answers to common questions, troubleshooting guides, and contact our support team."
        keywords="StoryCraft support, help center, FAQ, troubleshooting, contact support"
      />
      <div className="min-h-screen bg-black text-white">
        <Header />
        <main className="container mx-auto px-4 py-12">
          <div className="max-w-6xl mx-auto">
            <h1 className="text-4xl font-serif mb-8">Support Center</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
              {supportArticles.map((article) => (
                <Link key={article.href} href={article.href}>
                  <a className="p-6 bg-zinc-900 rounded-lg hover:bg-zinc-800 transition-colors group">
                    <div className="flex items-start space-x-4">
                      <article.icon className="w-6 h-6 text-red-500 mt-1" />
                      <div className="flex-1">
                        <h2 className="text-2xl font-serif mb-2">{article.title}</h2>
                        <p className="text-gray-400 mb-4">{article.description}</p>
                        <div className="flex items-center text-red-500 group-hover:text-red-400">
                          <span>Read more</span>
                          <ChevronRight className="w-4 h-4 ml-1" />
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              ))}
            </div>

            <div className="max-w-2xl mx-auto bg-zinc-900 rounded-lg p-8">
              <h2 className="text-3xl font-serif mb-6">Contact Support</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium mb-2">
                    Name
                  </label>
                  <Input
                    id="name"
                    required
                    className="bg-zinc-800 border-zinc-700"
                    placeholder="Your name"
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium mb-2">
                    Email
                  </label>
                  <Input
                    id="email"
                    type="email"
                    required
                    className="bg-zinc-800 border-zinc-700"
                    placeholder="your@email.com"
                  />
                </div>
                
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium mb-2">
                    Subject
                  </label>
                  <Input
                    id="subject"
                    required
                    className="bg-zinc-800 border-zinc-700"
                    placeholder="What can we help you with?"
                  />
                </div>
                
                <div>
                  <label htmlFor="message" className="block text-sm font-medium mb-2">
                    Message
                  </label>
                  <Textarea
                    id="message"
                    required
                    className="bg-zinc-800 border-zinc-700 min-h-[150px]"
                    placeholder="Describe your issue or question..."
                  />
                </div>
                
                <Button type="submit" className="w-full bg-[#dc2626] hover:bg-[#e33a3a]">
                  Send Message
                </Button>
              </form>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default SupportPage;