import React from 'react';
import { Link } from 'wouter';
import { Header } from '@/components/app/Header';
import Footer from '@/components/app/Footer';
import { SEO } from '@/components/SEO';

const PrivacyPolicy = () => {
  return (
    <>
      <SEO 
        title="Privacy Policy"
        description="StoryCraft's privacy policy. Learn how we protect your data, what information we collect, and your privacy rights."
        keywords="StoryCraft privacy policy, data protection, user privacy, data collection"
      />
      <div className="min-h-screen bg-black text-white">
        <Header />
        <main className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-serif mb-8">Privacy Policy</h1>
            
            <section className="space-y-6">
              <div>
                <h2 className="text-2xl font-serif mb-4">1. Information We Collect</h2>
                <p className="text-gray-300">
                  We collect information that you provide directly to us, including:
                </p>
                <ul className="list-disc pl-6 text-gray-300 space-y-2 mt-2">
                  <li>Account information (name, email, password)</li>
                  <li>Profile information</li>
                  <li>Content you create and share</li>
                  <li>Communications with us</li>
                </ul>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">2. How We Use Your Information</h2>
                <ul className="list-disc pl-6 text-gray-300 space-y-2">
                  <li>To provide and maintain our services</li>
                  <li>To improve and personalize your experience</li>
                  <li>To communicate with you</li>
                  <li>To ensure platform security</li>
                </ul>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">3. Data Security</h2>
                <p className="text-gray-300">
                  We implement appropriate security measures to protect your personal information.
                  This includes encryption, secure servers, and regular security audits.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">4. Your Rights</h2>
                <p className="text-gray-300">
                  You have the right to:
                </p>
                <ul className="list-disc pl-6 text-gray-300 space-y-2 mt-2">
                  <li>Access your personal data</li>
                  <li>Request corrections to your data</li>
                  <li>Request deletion of your data</li>
                  <li>Opt-out of marketing communications</li>
                </ul>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">5. Cookies and Tracking</h2>
                <p className="text-gray-300">
                  We use cookies and similar technologies to enhance your experience and collect
                  usage data to improve our services.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">6. Updates to Privacy Policy</h2>
                <p className="text-gray-300">
                  We may update this privacy policy from time to time. We will notify you of any
                  significant changes by posting a notice on our website.
                </p>
              </div>
            </section>
            
            <div className="mt-8">
              <Link href="/">
                <a className="text-red-500 hover:text-red-400">← Back to Home</a>
              </Link>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
