import React, { useState } from 'react';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { Button } from '../ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Lock, Sparkles } from 'lucide-react';
import { PremiumDialog } from '../premium/PremiumDialog';

export const FeatureDisplay = () => {
  const { getAvailableFeatures, getUpgradeFeatures, userTier } = useFeatureFlag();
  const [showPremiumDialog, setShowPremiumDialog] = useState(false);

  const availableFeatures = getAvailableFeatures();
  const upgradeFeatures = getUpgradeFeatures();

  const handleUpgradeClick = () => {
    setShowPremiumDialog(true);
  };

  return (
    <div className="space-y-6">
      {/* Available Features */}
      <div>
        <h2 className="text-2xl font-bold mb-4">Your Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {availableFeatures.map((feature) => (
            <Card key={feature.id}>
              <CardHeader>
                <CardTitle>{feature.title}</CardTitle>
                <CardDescription>{feature.description}</CardDescription>
              </CardHeader>
            </Card>
          ))}
        </div>
      </div>

      {/* Premium Features (if not premium) */}
      {upgradeFeatures.length > 0 && (
        <div>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">Premium Features</h2>
            <Button
              variant="premium"
              onClick={handleUpgradeClick}
              className="gap-2"
            >
              <Sparkles className="h-4 w-4" />
              Upgrade to Premium
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {upgradeFeatures.map((feature) => (
              <Card key={feature.id} className="border-2 border-yellow-500/20">
                <CardHeader>
                  <div className="flex items-center gap-2">
                    <Lock className="h-5 w-5 text-yellow-500" />
                    <CardTitle>{feature.title}</CardTitle>
                  </div>
                  <CardDescription>{feature.description}</CardDescription>
                </CardHeader>
                <CardContent>
                  <Button 
                    variant="premium" 
                    className="w-full"
                    onClick={handleUpgradeClick}
                  >
                    Unlock Feature
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      )}

      {/* Current Plan Status */}
      <div className="mt-6 p-4 bg-secondary rounded-lg">
        <p className="text-sm text-muted-foreground">
          Current Plan: {userTier === 'premium-authenticated' 
            ? '✨ Premium User' 
            : userTier === 'free-authenticated' 
              ? '🆓 Free User' 
              : '👤 Guest User'}
        </p>
      </div>

      {/* Premium Dialog */}
      <PremiumDialog
        open={showPremiumDialog}
        onClose={() => setShowPremiumDialog(false)}
      />
    </div>
  );
};
