import { create } from 'zustand';
import type { User } from '../types/user';
import { supabase } from '../lib/supabase';

interface AuthState {
  user: User | null;
  token: string | null;
  setUser: (user: User | null) => void;
  setToken: (token: string | null) => void;
  initialize: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  token: null,
  setUser: (user) => set({ user }),
  setToken: (token) => {
    console.log('Setting token:', token);
    set({ token });
  },
  initialize: async () => {
    console.log('Initializing auth store...');
    const { data: { session } } = await supabase.auth.getSession();
    console.log('Initial session:', session);
    
    if (session?.access_token) {
      console.log('Setting initial token:', session.access_token);
      set({ token: session.access_token });
    }
    
    // Listen for auth changes
    supabase.auth.onAuthStateChange((_event, session) => {
      console.log('Auth state changed:', _event, 'Session:', session);
      set({ token: session?.access_token || null });
    });
  },
}));
