import React from 'react';
import { Link } from 'wouter';
import { ChevronLeft } from 'lucide-react';
import { Button } from "@/components/ui/button";
import Footer from '@/components/app/Footer';
import { Header } from '@/components/app/Header';
import { SEO } from '@/components/SEO';

const StoryGenerationGuidePage = () => {
  return (
    <>
      <SEO 
        title="How to Generate Stories"
        description="Learn how to use StoryCraft's AI-powered story generation tools. Step-by-step guide to creating engaging stories."
        keywords="StoryCraft story generation, AI stories, writing guide, story creation"
      />
      <div className="min-h-screen bg-black text-white">
        <Header />
        <main className="container mx-auto px-4 py-12 max-w-4xl">
          <div className="mb-8">
            <Link href="/support">
              <a className="inline-flex items-center text-gray-400 hover:text-white transition-colors">
                <ChevronLeft className="w-4 h-4 mr-1" />
                Back to Support
              </a>
            </Link>
          </div>

          <h1 className="text-4xl font-serif mb-8">How to Generate Stories</h1>
          
          <section className="space-y-8">
            <div>
              <h2 className="text-2xl font-serif mb-4">1. Start a New Story</h2>
              <p className="text-gray-300 mb-4">
                Click the "Create New Story" button on your dashboard or use the quick start option 
                in the navigation menu. This will take you to our story generation interface.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-serif mb-4">2. Choose Your Settings</h2>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Select your preferred genre</li>
                <li>Choose the story length (short, medium, or long)</li>
                <li>Set the tone (dramatic, humorous, mysterious, etc.)</li>
                <li>Add any specific themes or elements you want to include</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-serif mb-4">3. Provide Initial Input</h2>
              <p className="text-gray-300 mb-4">
                Enter a brief description of your story idea or the main elements you want to include. 
                Our AI will use this as a foundation for generating your story.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-serif mb-4">4. Generate and Review</h2>
              <p className="text-gray-300 mb-4">
                Click "Generate Story" and wait for our AI to create your story. Once generated, 
                you can review the content and make any desired adjustments.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-serif mb-4">5. Edit and Refine</h2>
              <p className="text-gray-300 mb-4">
                Use our editing tools to refine your story. You can:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Modify specific sections</li>
                <li>Adjust the tone or style</li>
                <li>Add or remove content</li>
                <li>Get AI suggestions for improvements</li>
              </ul>
            </div>

            <div>
              <h2 className="text-2xl font-serif mb-4">Need More Help?</h2>
              <p className="text-gray-300 mb-4">
                If you need additional assistance, our support team is here to help.
              </p>
              <Button className="bg-primary hover:bg-primary/90">
                Contact Support
              </Button>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default StoryGenerationGuidePage;
