import React from 'react';
import { useEffect, useState } from "react";
import { Card, CardContent } from "./ui/card";
import { Progress } from "./ui/progress";

const explicitLevelFacts = {
  PG: [
    "Did you know? The first story ever written was likely a tale of adventure!",
    "Fun fact: Reading stories can improve empathy and social understanding.",
    "Stories have been used to pass down knowledge for thousands of years.",
    "The human brain processes stories differently than other types of information.",
    "Stories can be found in every culture throughout history.",
  ],
  R: [
    "Romance novels account for over $1 billion in annual sales.",
    "Love stories have been popular since ancient times.",
    "Shakespeare wrote numerous romantic tales.",
    "Romance is one of the most enduring literary genres.",
    "Stories help us understand human relationships better.",
  ],
  explicit: [
    "Adult content has existed in literature throughout history.",
    "Many classic works contained mature themes.",
    "Different cultures have varying views on explicit content.",
    "Content ratings help readers make informed choices.",
    "Storytelling evolves with societal norms.",
  ],
};

export default function LoadingScreen({ explicitLevel = "PG" }) {
  const [progress, setProgress] = useState(0);
  const [fact, setFact] = useState("");
  const facts = explicitLevelFacts[explicitLevel as keyof typeof explicitLevelFacts] || explicitLevelFacts.PG;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!facts || facts.length === 0) return;
    
    setFact(facts[Math.floor(Math.random() * facts.length)]);
    const interval = setInterval(() => {
      setFact(facts[Math.floor(Math.random() * facts.length)]);
    }, 3000);

    return () => clearInterval(interval);
  }, [facts]);

  return (
    <Card className="w-full bg-black border-gray-800">
      <CardContent className="pt-6">
        <div className="space-y-8">
          <div className="space-y-2">
            <Progress value={progress} className="bg-gray-800" />
            <p className="text-center text-sm text-gray-400">
              Generating your story... {progress}%
            </p>
          </div>
          <div className="text-center text-gray-400 italic">{fact}</div>
        </div>
      </CardContent>
    </Card>
  );
}
