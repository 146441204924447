import React, { lazy, Suspense, useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Sparkles, Book, Headphones, MapPin, User, ArrowRight } from "lucide-react";
import ErrorBoundary from "@/components/ErrorBoundary";
import { useQuery } from "@tanstack/react-query";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import type { Story } from "../../types/schema";
import { getRecentStories } from "../lib/api";
import { Header } from '@/components/app/Header';
import { SEO } from '@/components/SEO';

// Lazy load components
const ParticleBackground = lazy(() => import('@/components/app/ParticleBackground'));
const FAQSection = lazy(() => import('@/components/app/FAQSection'));
const Footer = lazy(() => import('@/components/app/Footer'));
const CTASection = lazy(() => import('@/components/app/CTASection'));

const Loading = () => (
  <div className="flex items-center justify-center min-h-[200px]">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
  </div>
);

const StoryDialog = ({
  story,
  open,
  onClose,
}: {
  story: Story;
  open: boolean;
  onClose: () => void;
}) => (
  <Dialog open={open} onOpenChange={onClose}>
    <DialogContent className="max-w-3xl bg-[#1A1A1A] text-white">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold">{story.title}</DialogTitle>
      </DialogHeader>
      <ScrollArea className="h-[500px]">
        <div className="space-y-4 p-4">
          {story.content.split("\n").map((paragraph: string, index: number) => (
            <p key={index} className="text-gray-300">{paragraph}</p>
          ))}
        </div>
      </ScrollArea>
    </DialogContent>
  </Dialog>
);

const HomePage = () => {
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [visibleStories, setVisibleStories] = useState(9);

  const { data: recentStories = [], isLoading } = useQuery({
    queryKey: ["recentStories"],
    queryFn: getRecentStories,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const displayedStories = recentStories.slice(0, Math.min(visibleStories, 27));
  const hasMore = visibleStories < Math.min(recentStories.length, 27);

  const loadMore = () => {
    setVisibleStories(prev => Math.min(prev + 9, 27));
  };

  return (
    <>
      <SEO 
        title="NSFW & Erotic AI Story Generator"
        description="Create amazing stories with AI-powered storytelling. Write, share, and explore creative narratives with StoryCraft."
        keywords="ai story generator, nsfw stories, erotic stories, porn stories, kinky stories"
      />
      <div>
        <div className="min-h-screen bg-[#000000] text-white">
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <ParticleBackground />
            </Suspense>
          </ErrorBoundary>

          <Header />

          {/* Hero Section */}
          <section className="relative pt-32 pb-20 text-center px-4">
            <h1 className="text-4xl md:text-5xl font-serif max-w-4xl mx-auto mb-6">
              Unleash Your Naughty Side With Our Free AI Erotic Story Generator
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto mb-8">
              Discover a world of endless possibilities, where your imagination takes the lead, and AI helps you craft stories tailored to your vision.
            </p>

            <a href="/create">
              <Button
                size="lg"
                className="bg-red-600 hover:bg-red-700 text-white px-8 py-6 rounded-full text-lg font-medium"
              >
                <Sparkles className="w-5 h-5 mr-2" />
                Start Writing for Free
              </Button>
            </a>

            {/* Stats */}
            <div className="flex justify-center gap-12 mt-16">
              <div className="text-center">
                <div className="text-3xl font-bold">11K+</div>
                <div className="text-gray-400 text-xl">happy users</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold">23K+</div>
                <div className="text-gray-400 text-xl">stories created</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold">4.9</div>
                <div className="text-yellow-400 text-xl">★★★★★</div>
              </div>
            </div>
          </section>

          {/* Testimonials Section */}
          <section className="py-20 px-4 bg-[#0a0a0a]">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-4xl font-serif text-center mb-12">
              What Our Users Say
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {/* Testimonial 1 */}
                <div className="bg-[#1d1c1c] rounded-lg p-6 text-center">
                  <div className="w-24 h-24 mx-auto mb-4 rounded-full overflow-hidden">
                    <img
                      src="/6807369.png"
                      alt="Sarah's profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-gray-300 mb-4">
                    "I love it! It's very easy to use and the quality is great. If you're in the mood for something naughty, I haven't found a better tool yet."
                  </p>
                  <p className="text-white-800 font-bold">Sarah</p>
                </div>

                {/* Testimonial 2 */}
                <div className="bg-[#1d1c1c] rounded-lg p-6 text-center">
                  <div className="w-24 h-24 mx-auto mb-4 rounded-full overflow-hidden">
                    <img
                      src="/3465434.jpg"
                      alt="Sir Bob's profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-gray-300 mb-4">
                    "I've tried other NSFW AI tools, but none compare to SO NAUGHTY.
                    The quality of suggestions and ease of use is unmatched!"
                  </p>
                  <p className="text-white-800 font-bold">Sir Bob</p>
                </div>

                {/* Testimonial 3 */}
                <div className="bg-[#1d1c1c] rounded-lg p-6 text-center">
                  <div className="w-24 h-24 mx-auto mb-4 rounded-full overflow-hidden">
                    <img
                      src="/7465453.jpg"
                      alt="Jake's profile"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-gray-300 mb-4">
                    "Yes this is the very best Ai erotica story generator I love it - I've been using this app it's so Good!"
                  </p>
                  <p className="text-white-800 font-bold">Jake</p>
                </div>
              </div>
            </div>
          </section>

          {/* Features Section */}
          <section className="py-20 px-4">
            <div className="max-w-6xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-4xl font-serif mb-4">
                  Bring Your Stories to Life
                </h2>
                <p className="text-gray-300 max-w-3xl mx-auto">
                Start writing kinky/erotic/NSFW stories for free with our advanced AI-powered writer. Customize every detail to create unique and detailed stories.
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-20">
                <Card className="bg-[#1d1c1c] border-none p-8">
                  <div className="flex flex-col gap-6">
                    <User className="w-12 h-12 text-red-500" />
                    <h3 className="text-2xl font-serif text-white">Set the Stage for Seduction</h3>
                    <p className="text-gray-300">
                      Design breathtaking settings and thrilling actions for your stories. Whether it's a cozy candlelit room or a fiery forbidden encounter, our AI helps you make every scene unforgettable.
                    </p>
                  </div>
                </Card>

                <Card className="bg-[#1d1c1c] border-none p-8">
                  <div className="flex flex-col gap-6">
                    <MapPin className="w-12 h-12 text-red-500" />
                    <h3 className="text-2xl font-serif text-white">Never Run Out of Ideas</h3>
                    <p className="text-gray-300">
                      Stuck for inspiration? Let our intelligent AI suggestion feature spice things up with prompts, plot twists, and engaging details to keep your story flowing.
                    </p>
                  </div>
                </Card>

                <Card className="bg-[#1d1c1c] border-none p-8">
                  <div className="flex flex-col gap-6">
                    <Book className="w-12 h-12 text-red-500" />
                    <h3 className="text-2xl font-serif text-white">Smart Story Engine</h3>
                    <p className="text-gray-300">
                      Our AI understands context and builds compelling narratives that keep readers engaged from start to finish.
                    </p>
                  </div>
                </Card>

                <Card className="bg-[#1d1c1c] border-none p-8">
                  <div className="flex flex-col gap-6">
                    <Headphones className="w-12 h-12 text-red-500" />
                    <h3 className="text-2xl font-serif text-white">High-Quality Audio Experience</h3>
                    <p className="text-gray-300">
                      Listen to your stories come to life with our premium text-to-speech feature, perfect for a relaxing time or multitasking.
                    </p>
                  </div>
                </Card>
              </div>
            </div>
          </section>

           {/* Features Grid Section */}
           <section className="relative py-20 px-4">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-4xl font-serif mb-4 text-center">
                Premium Features
              </h2>
              <p className="text-gray-300 max-w-3xl mx-auto text-center mb-12">
                Unlock the full potential of StoryCraft with our premium features. Create unlimited stories, generate stunning visuals, and enjoy priority support.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Feature 1 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">More Than Enough 💞</h3>
                    <p className="text-gray-300">50 chapters every 24 hours – that's a full book's worth of content daily. Perfect for those who can't get enough of our spicy tales.</p>
                  </CardContent>
                </Card>

                {/* Feature 2 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Spicy and Sensational 🔥</h3>
                    <p className="text-gray-300">Dive into NSFW generations that deliver thrilling and sensational content, crafted to enhance your intimacy and excitement.</p>
                  </CardContent>
                </Card>

                {/* Feature 3 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Premium Quality ✨</h3>
                    <p className="text-gray-300">Our premium users enjoy content powered by the most advanced AI models, ensuring superior quality in both text and image generation.</p>
                  </CardContent>
                </Card>

                {/* Feature 4 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Unlimited Characters & Locations 🎭</h3>
                    <p className="text-gray-300">Unlock the ability to create stories with unlimited characters and locations, making your tales more immersive.</p>
                  </CardContent>
                </Card>

                {/* Feature 5 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Premium Image Styles + NSFW 🎨</h3>
                    <p className="text-gray-300">Enjoy premium image styles such as Digital Art, Anime, and Soft, adding a unique touch to your visual storytelling. Plus, access NSFW images for a more intimate experience.</p>
                  </CardContent>
                </Card>

                {/* Feature 6 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Unlimited Regenerations 🔄</h3>
                    <p className="text-gray-300">Regenerate images as many times as you like, ensuring each story is complemented by the perfect visual representation.</p>
                  </CardContent>
                </Card>

                {/* Feature 7 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Double Audio Credits 🎶</h3>
                    <p className="text-gray-300">Premium users receive double the audio credits with every purchase, allowing you to listen to more stories and immerse yourself in the moment.</p>
                  </CardContent>
                </Card>

                {/* Feature 8 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Export Stories in PDF 📜</h3>
                    <p className="text-gray-300">Download your stories in full, allowing you to share them with friends or print them out for a lasting keepsake.</p>
                  </CardContent>
                </Card>

                {/* Feature 9 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Prioritised Support ⚡</h3>
                    <p className="text-gray-300">Premium users receive priority support from our dedicated team, ensuring your queries are resolved quickly and efficiently.</p>
                  </CardContent>
                </Card>

                {/* Feature 10 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Privacy First 🛡️</h3>
                    <p className="text-gray-300">We put your privacy at the forefront. Rest assured, we don't store any personal data, ensuring your peace of mind.</p>
                  </CardContent>
                </Card>

                {/* Feature 11 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Confidentiality Guaranteed 🤐</h3>
                    <p className="text-gray-300">Your submissions remain your secret. Every entry is treated with the utmost confidentiality, safeguarding your privacy.</p>
                  </CardContent>
                </Card>

                {/* Feature 12 */}
                <Card className="bg-[#1A1A1A] border-gray-800">
                  <CardContent className="pt-6">
                    <h3 className="text-2xl font-serif mb-2 text-white">Discreet Billing 💳</h3>
                    <p className="text-gray-300">CCBill.com *DigitalKwart will appear on your Cardholder statement</p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>

          {/* Recent Stories Section */}
          <section className="py-20 px-4">
            <div className="max-w-6xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-4xl font-serif mb-4">
                  Recent Stories
                </h2>
                <p className="text-gray-300 mt-2 max-w-3xl mx-auto">
                  We moderate and review all stories generated by our AI to ensure they meet our content policy guidelines. All stories are 100% fictional and AI-written.
                </p>
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                </div>
              ) : recentStories.length > 0 ? (
                <div className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {displayedStories.map((story: Story) => (
                      <Card
                        key={story.id}
                        className="bg-[#1d1c1c] border-none p-6 cursor-pointer hover:bg-accent/10 transition-colors hover:shadow-lg"
                        onClick={() => setSelectedStory(story)}
                      >
                        <CardHeader>
                          <CardTitle className="text-xl font-serif text-gray-100">
                            {story.title}
                          </CardTitle>
                        </CardHeader>
                        <CardContent>
                          <p className="text-gray-300 line-clamp-3">{story.content}</p>
                          <div className="flex justify-end mt-4">
                            <Button variant="ghost" size="sm" className="text-red-500 hover:text-red-400 hover:bg-red-500/10">
                              Read More <ArrowRight className="w-4 h-4 ml-2" />
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>

                  {hasMore && (
                    <div className="flex justify-center mt-8">
                      <Button
                        variant="outline"
                        onClick={loadMore}
                        className="text-red-500 hover:text-red-400 hover:bg-red-500/10"
                      >
                        Load More Stories
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-300">
                  No stories available at the moment.
                </div>
              )}
            </div>
          </section>

          <ErrorBoundary>
            <Suspense fallback={<Loading />}>

          {/* Info Section */}
          <section className="py-20 px-4">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-4xl font-serif text-center mb-12">
                How to Use Our Erotic AI Story Generator
              </h2>
              
              <div className="grid md:grid-cols-3 gap-8">
                {/* Getting Started */}
                <Card className="bg-[#1d1c1c] border-none p-8">
                  <CardContent>
                    <h3 className="text-2xl font-serif mb-4 text-white">Getting Started</h3>
                    <p className="text-gray-300">
                      Simply visit the website, and you're halfway there. No sign-ups required, start creating immediately.
                    </p>
                  </CardContent>
                </Card>

                {/* Step-by-step Guide */}
                <Card className="bg-[#1d1c1c] border-none p-8">
                  <CardContent>
                    <h3 className="text-2xl font-serif mb-4 text-white">Step-by-step Guide</h3>
                    <ul className="text-gray-300 space-y-4">
                    <li>
                        <span className="font-semibold">1. Create characters:</span> Input your characters' names, appearance and personalities. You can be as detailed as you like.
                      </li>
                      <li>
                        <span className="font-semibold">2. Describe scenario:</span> Input your desired scenario and themes for the story. You can add as many details as you can.
                      </li>
                      <li>
                        <span className="font-semibold">3. Click 'Generate':</span> Run the process. It takes under 30 seconds. Sit back and and enjoy the magic.
                      </li>
                    </ul>
                  </CardContent>
                </Card>

                {/* Tips */}
                <Card className="bg-[#1d1c1c] border-none p-8">
                  <CardContent>
                    <h3 className="text-2xl font-serif mb-4 text-white">Tips</h3>
                    <ul className="text-gray-300 space-y-4">
                      <li>The clearer and more specific your inputs are, the better the AI can craft a story that matches your expectations.</li>
                      <li>Trying different tones for the same scenario can yield interesting variations.</li>
                      <li>Remember, better context leads to better results. Feel free to experiment!</li>
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>

              <FAQSection />
              <CTASection />
              <Footer />
            </Suspense>
          </ErrorBoundary>

          {selectedStory && (
            <StoryDialog
              story={selectedStory}
              open={!!selectedStory}
              onClose={() => setSelectedStory(null)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage;
