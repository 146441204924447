import React from 'react';
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import type { StoryGeneration } from "@/../../types/schema";
import { useState } from "react";
import { useToast } from "../components/ui/use-toast";
import SexActsSelection from "./SexActsSelection";
import { WandSparkles } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";
import { GenreCombobox } from "./GenreCombobox";
import { validateAndSanitizeInput, MAX_LENGTHS, AI_KEYWORDS, PROMPT_INJECTION_PATTERNS } from '../lib/validation';
import { useAuthStore } from "../store/auth";
import { API_URL } from '../lib/config';

interface StoryPlotProps {
  value: Partial<StoryGeneration>;
  onChange: (data: Partial<StoryGeneration>) => void;
}

const narrativeStyles = [
  { value: "first-person", label: "First person (I, we)" },
  { value: "second-person", label: "Second person (you)" },
  { value: "third-person", label: "Third person (he, she, they)" }
];

export default function StoryPlot({
  value,
  onChange,
}: StoryPlotProps) {
  const { toast } = useToast();
  const [isGeneratingSuggestion, setIsGeneratingSuggestion] = useState(false);
  const { token } = useAuthStore();

  const updateField = (field: string, newValue: any) => {
    // For text fields that need validation
    if (field === 'scene') {
      // Only check for AI prompts and length, preserve spaces
      if (newValue.length > MAX_LENGTHS.SCENE_DESCRIPTION || 
          AI_KEYWORDS.some(keyword => newValue.toLowerCase().includes(keyword.toLowerCase())) ||
          PROMPT_INJECTION_PATTERNS.some(pattern => pattern.test(newValue))) {
        toast({
          title: "Invalid Input",
          description: `Please check your input. Maximum ${MAX_LENGTHS.SCENE_DESCRIPTION} characters allowed. No AI instructions or special formatting.`,
          variant: "destructive"
        });
        return;
      }
    }

    onChange({
      ...value,
      [field]: newValue
    });
  };

  const generateSuggestion = async () => {
    try {
      setIsGeneratingSuggestion(true);
      
      // Only send fields that have values
      const payload: any = {};
      if (value.scene) payload.scene = value.scene;
      if (value.narrativeStyle) payload.narrativeStyle = value.narrativeStyle;
      if (value.explicitLevel) payload.explicitLevel = value.explicitLevel;
      if (value.characters?.length) {
        payload.characters = value.characters.map(c => ({
          name: c.name || '',
          gender: c.gender || ''
        })).filter(c => c.name && c.gender);
      }
      if (value.genre) payload.genre = value.genre;

      const response = await fetch(`${API_URL}/api/generate-suggestion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ error: 'Failed to generate suggestion' }));
        if (errorData.isRateLimit) {
          toast({
            title: "Rate Limit Reached",
            description: errorData.error,
            variant: "default",
            duration: 10000, // Show for 10 seconds
          });
        } else {
          toast({
            title: "Error",
            description: errorData.error || 'Failed to generate suggestion',
            variant: "destructive",
          });
        }
        throw new Error(errorData.error || 'Failed to generate suggestion');
      }

      const data = await response.json();
      onChange({
        ...value,
        scene: data.suggestion,
      });
      
      toast({
        title: "Suggestion Generated",
        description: "A new story description has been generated for you!",
      });
    } catch (error) {
      console.error('Error generating suggestion:', error);
      toast({
        title: "Error",
        description: "Failed to generate story suggestion. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGeneratingSuggestion(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-2xl font-bold mb-4 text-white">Create Your Story Plot</h2>
        <p className="text-gray-400 mb-6">
          Define the key elements of your story.
        </p>
      </div>

      <div className="space-y-2">
          <Label htmlFor="narrative-style" className="text-white">
            Narrative Style <span className="text-red-500">*</span>
          </Label>
          <Select
            value={value.narrativeStyle || ""}
            onValueChange={(newValue) => updateField("narrativeStyle", newValue)}
          >
            <SelectTrigger id="narrative-style">
              <SelectValue placeholder="Select narrative style" />
            </SelectTrigger>
            <SelectContent>
              {narrativeStyles.map((style) => (
                <SelectItem key={style.value} value={style.value}>
                  {style.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="scene" className="text-white">
            Story Description <span className="text-red-500">*</span>
          </Label>
          <Textarea
            id="scene"
            value={value.scene || ""}
            onChange={(e) => updateField("scene", e.target.value)}
            maxLength={MAX_LENGTHS.SCENE_DESCRIPTION}
            placeholder="Describe your story scene and the actions you want the characters to perform. This field is required for story generation. The more specific detail you give, the better the story will be."
            className="min-h-[200px]"
            required
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={generateSuggestion}
                  disabled={isGeneratingSuggestion || !value.explicitLevel || !value.characters?.length || !value.narrativeStyle}
                  className="mt-2"
                >
                  {isGeneratingSuggestion ? (
                    "Generating..."
                  ) : (
                    <>
                      <WandSparkles className="me-2 pulsate" size={16} />
                      {value.scene ? "Regenerate Story Prompt" : "Suggest Story Prompt"}
                    </>
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white border border-gray-800">
                <p>Let AI suggest a story prompt based on your characters and settings!</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {(value.explicitLevel === "explicit" || value.explicitLevel === "very-explicit") && (
          <div className="space-y-2">
            <SexActsSelection
              value={value.sexActs || []}
              onChange={(newValue) => updateField("sexActs", newValue)}
              disabled={isGeneratingSuggestion}
            />
          </div>
        )}

<div className="space-y-2">
          <Label htmlFor="genre" className="text-white">
            Theme
          </Label>
          <GenreCombobox
            value={value.genre || []}
            onChange={(newValue) => updateField("genre", newValue)}
            contentLevel={value.explicitLevel === "moderate" ? "moderate" : "explicit"}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="location" className="text-white">
            Location
          </Label>
          <Input
            id="location"
            value={value.location || ""}
            onChange={(e) => updateField("location", e.target.value)}
            placeholder="Enter city, country, or fictional location"
          />
        </div>

      </div>
    </div>
  );
}
