import React from 'react';
import { Link } from 'wouter';
import { ChevronLeft } from 'lucide-react';
import { Button } from "@/components/ui/button";
import Footer from '@/components/app/Footer';
import { Header } from '@/components/app/Header';
import { SEO } from '@/components/SEO';

const TroubleshootingGuidePage = () => {
  return (
    <>
      <SEO 
        title="Troubleshooting Guide"
        description="Find solutions to common issues with StoryCraft. Get help with technical requirements, error messages, and platform compatibility."
        keywords="StoryCraft troubleshooting, technical support, error fixes, platform requirements"
      />
      <div className="min-h-screen bg-black text-white">
        <Header />
        <main className="container mx-auto px-4 py-12 max-w-4xl">
          <div className="mb-8">
            <Link href="/support">
              <a className="inline-flex items-center text-gray-400 hover:text-white transition-colors">
                <ChevronLeft className="w-4 h-4 mr-1" />
                Back to Support
              </a>
            </Link>
          </div>

          <h1 className="text-4xl font-serif mb-8">Troubleshooting Guide</h1>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-serif mb-4">Common Issues</h2>
              <p className="text-gray-300 mb-4">
                Here are solutions to frequently encountered problems:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Story generation is taking too long</li>
                <li>Unable to save story drafts</li>
                <li>Login or authentication issues</li>
                <li>Problems with image uploads</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-serif mb-4">Technical Requirements</h2>
              <p className="text-gray-300 mb-4">
                Ensure your system meets these requirements:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Modern web browser (Chrome, Firefox, Safari, Edge)</li>
                <li>JavaScript enabled</li>
                <li>Stable internet connection</li>
                <li>Cookies enabled for authentication</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-serif mb-4">Getting Help</h2>
              <p className="text-gray-300 mb-4">
                If you're still experiencing issues:
              </p>
              <ul className="list-disc pl-6 text-gray-300 space-y-2">
                <li>Check our status page for any outages</li>
                <li>Clear your browser cache and cookies</li>
                <li>Contact our support team</li>
                <li>Join our Discord community for help</li>
              </ul>
            </section>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default TroubleshootingGuidePage;
