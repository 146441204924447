import type { Story, StoryGeneration } from "@/../../types/schema";
import { API_URL } from './config';
import { useAuthStore } from '../store/auth';
import { supabase } from './supabase';

async function getAuthHeader() {
  const token = useAuthStore.getState().token;
  return token ? `Bearer ${token}` : '';
}

export async function registerUser(username: string, password: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/auth/register`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: "Registration failed. Please try again." }));
    throw new Error(data.error || "Registration failed. Please try again.");
  }

  const data = await response.json();
  if (!data.success || !data.user) {
    throw new Error("Registration failed. Please try again.");
  }

  return data.user;
}

export async function login(username: string, password: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/auth/login`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: "Login failed. Please try again." }));
    throw new Error(data.error || "Login failed. Please try again.");
  }

  return response.json();
}

export async function logout() {
  const authHeader = await getAuthHeader();
  await fetch(`${API_URL}/api/auth/logout`, { 
    method: "POST",
    headers: { 
      "Authorization": authHeader
    }
  });
}

export async function generateStory(data: StoryGeneration) {
  const authHeader = await getAuthHeader();
  
  // Let the worker handle both story generation and saving
  const response = await fetch(`${API_URL}/api/stories/generate`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({ error: "Failed to generate story" }));
    throw new Error(errorData.error || "Failed to generate story");
  }

  return response.json();
}

export async function verifyAge() {
  const { data: { session } } = await supabase.auth.getSession();
  console.log('Current session:', session);

  // For logged-in users, we need to update their metadata
  if (session?.access_token) {
    const response = await fetch(`${API_URL}/api/users/verify-age`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${session.access_token}`
      }
    });
    
    if (!response.ok) {
      const error = await response.json().catch(() => ({ error: "Failed to verify age" }));
      console.error('Verify age error:', error);
      throw new Error(error.error || "Failed to verify age");
    }
    
    const result = await response.json();
    console.log('Verify age response:', result);
    return result;
  }
  
  // For non-logged-in users, just return success
  return { success: true };
}

export async function getUserStories(): Promise<Story[]> {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/stories`, {
    headers: { 
      "Authorization": authHeader
    }
  });
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error("Please log in to view your stories");
    }
    throw new Error("Failed to fetch stories");
  }
  const data = await response.json();
  console.log('Stories from server:', data);
  return data;
}

export async function getRecentStories(): Promise<Story[]> {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/stories/recent`, {
    headers: { 
      "Authorization": authHeader
    }
  });

  if (!response.ok) {
    throw new Error("Failed to fetch recent stories");
  }

  return response.json();
}

export async function updateStory(id: number, updates: { title: string; content: string }) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/stories/${id}`, {
    method: "PUT",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify(updates),
  });
  if (!response.ok) throw new Error("Failed to update story");
  return response.json();
}

export async function register(username: string, password: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/auth/register`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify({ username, password }),
  });
  
  if (!response.ok) {
    try {
      const data = await response.json();
      if (response.status === 409) {
        throw new Error("Username already exists. Please choose a different username.");
      }
      throw new Error(data.error || "Registration failed. Please try again.");
    } catch (e) {
      if (e instanceof SyntaxError) {
        // JSON parse error
        throw new Error("Registration failed. Please try again later.");
      }
      throw e; // Re-throw other errors
    }
  }
  
  try {
    return await response.json();
  } catch (e) {
    throw new Error("Failed to process server response. Please try again.");
  }
}

export async function updateStoryPrivacy(id: number, isPrivate: boolean) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/stories/${id}/privacy`, {
    method: "PUT",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify({ isPrivate }),
  });
  if (!response.ok) throw new Error("Failed to update story privacy");
  return response.json();
}

export async function continueStory(originalStory: string, explicitLevel: 'more' | 'less' | 'same', storyData: StoryGeneration, originalTitle: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/continue-story`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": authHeader
    },
    body: JSON.stringify({
      originalStory,
      explicitLevel,
      storyData,
      originalTitle,
      model: "gryphe/mythomax-l2-13b:free",
      maxTokens: 4000
    }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: "Failed to continue story" }));
    throw new Error(data.error || "Failed to continue story");
  }

  return response.json();
}

export async function deleteStory(id: string | number) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/stories/${id}`, {
    method: "DELETE",
    headers: { 
      "Authorization": authHeader
    }
  });
  
  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: "Failed to delete story" }));
    throw new Error(data.error || "Failed to delete story");
  }
  
  return response.json();
}

export async function createCheckoutSession(priceId: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/checkout/create-session`, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": authHeader
    },
    body: JSON.stringify({ priceId }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to create checkout session' }));
    throw new Error(data.error || 'Failed to create checkout session');
  }

  return response.json();
}

export async function getSubscriptionStatus() {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/subscription/status`, {
    headers: { 
      "Authorization": authHeader
    }
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to get subscription status' }));
    throw new Error(data.error || 'Failed to get subscription status');
  }

  return response.json();
}

export async function cancelSubscription() {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/subscription/cancel`, {
    method: 'POST',
    headers: { 
      "Authorization": authHeader
    }
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to cancel subscription' }));
    throw new Error(data.error || 'Failed to cancel subscription');
  }

  return response.json();
}

export interface PaymentHistory {
  id: string;
  amount: number;
  currency: string;
  status: 'succeeded' | 'failed' | 'pending';
  created: number;
  description: string;
  receiptUrl?: string;
}

export interface SubscriptionDetails {
  id: string;
  status: 'active' | 'canceled' | 'past_due' | 'incomplete';
  currentPeriodEnd: number;
  cancelAtPeriodEnd: boolean;
  planId: string;
  priceId: string;
  amount: number;
  currency: string;
  interval: 'month' | 'year';
}

export async function getPaymentHistory(): Promise<PaymentHistory[]> {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/subscription/payments`, {
    headers: { 
      "Authorization": authHeader
    }
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to get payment history' }));
    throw new Error(data.error || 'Failed to get payment history');
  }

  return response.json();
}

export async function getSubscriptionDetails(): Promise<SubscriptionDetails> {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/subscription/details`, {
    headers: { 
      "Authorization": authHeader
    }
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to get subscription details' }));
    throw new Error(data.error || 'Failed to get subscription details');
  }

  return response.json();
}

export async function updateSubscription(priceId: string) {
  const authHeader = await getAuthHeader();
  const response = await fetch(`${API_URL}/api/subscription/update`, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": authHeader
    },
    body: JSON.stringify({ priceId }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to update subscription' }));
    throw new Error(data.error || 'Failed to update subscription');
  }

  return response.json();
}

export async function getSession() {
  const authHeader = await getAuthHeader();
  console.log('Auth header for session:', authHeader); // Debug log
  
  const response = await fetch(`${API_URL}/api/auth/session`, {
    headers: { 
      "Authorization": authHeader
    }
  });
  
  if (!response.ok) {
    throw new Error("Failed to get session");
  }
  return response.json();
}

export async function getMe() {
  const authHeader = await getAuthHeader();
  console.log('Auth header for me:', authHeader); // Debug log
  
  const response = await fetch(`${API_URL}/api/auth/me`, {
    headers: { 
      "Authorization": authHeader
    }
  });
  
  if (!response.ok) {
    throw new Error("Failed to get user");
  }
  return response.json();
}