import React from 'react';
import { Label } from "../components/ui/label";
import { Checkbox } from "../components/ui/checkbox";
import { Alert, AlertDescription } from "../components/ui/alert";
import { AlertTriangle } from "lucide-react";
import { cn } from "../lib/utils";
import type { StoryGeneration } from "@/../../types/schema";

interface ExplicitLevelSelectionProps {
  value: Partial<StoryGeneration>;
  onChange: (data: Partial<StoryGeneration>) => void;
}

export default function ExplicitLevelSelection({
  value,
  onChange,
}: ExplicitLevelSelectionProps) {
  const updateField = (field: string, newValue: any) => {
    onChange({
      ...value,
      [field]: newValue
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-3xl font-bold mb-4 text-white">What type of story do you want to write?</h2>
      </div>

      <div className="space-y-12">
        <div className="space-y-4">
          <Label className="text-white mb-4 block">
            Choose your preferred level of explicit content: <span className="text-red-500">*</span>
          </Label>
          
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => updateField("explicitLevel", "moderate")}
              className={cn(
                "p-6 rounded-lg border-2 transition-all duration-200 cursor-pointer",
                value.explicitLevel === "moderate"
                  ? "border-red-500 bg-red-500/10 hover:bg-red-500/20"
                  : "border-gray-700 bg-gray-800 hover:border-red-500/50 hover:bg-gray-700"
              )}
            >
              <h3 className="text-xl font-semibold mb-2 text-white">Erotic Story</h3>
              <p className="text-gray-400 text-sm">
                Erotic and sensual content with suggestive themes. 
                Focuses on emotion, passion and intimacy without explicit details.
              </p>
            </button>

            <button
              onClick={() => updateField("explicitLevel", "very-explicit")}
              className={cn(
                "p-6 rounded-lg border-2 transition-all duration-200 cursor-pointer",
                value.explicitLevel === "very-explicit"
                  ? "border-red-500 bg-red-500/10 hover:bg-red-500/20"
                  : "border-gray-700 bg-gray-800 hover:border-red-500/50 hover:bg-gray-700"
              )}
            >
              <h3 className="text-xl font-semibold mb-2 text-white">Porn Story</h3>
              <p className="text-gray-400 text-sm">
                Highly detailed pornographic content with explicit descriptions.
                Focuses on raw physical encounters with graphic details.
              </p>
            </button>
          </div>
        </div>

        <Alert variant="default" className="flex items-start mt-8 sm:mt-12 bg-gray-900 border-gray-800 p-3 sm:p-4">
          <div className="mr-3 sm:mr-4 pt-1">
            <AlertTriangle className="h-5 w-5 text-yellow-500" />
          </div>
          <AlertDescription className="text-sm sm:text-base text-gray-300">
            <p className="mb-3">Content Warning: This website may contain mature themes including explicit content, violence, and potentially disturbing material. By using this site, you confirm that:</p>

            <div className="space-y-1 mb-3 pl-4">
              <p>1. You are 18 years or older</p>
              <p>2. You will not create or share content involving minors or animals</p>
              <p>3. You will not promote hate speech, discrimination, or extreme violence</p>
              <p>4. You understand that all content must be shared only with consenting adults</p>
            </div>

            <p className="text-xs sm:text-sm text-gray-400">You are solely responsible for the content you create and share. Violations may result in immediate content removal, permanent account suspension, and/or legal action where applicable.</p>
          </AlertDescription>
        </Alert>

        <div className="flex items-start space-x-4 mt-4">
          <div className="pt-0.5">
            <Checkbox
              id="age-confirmation"
              checked={value.ageConfirmed || false}
              onCheckedChange={(checked) => 
                updateField("ageConfirmed", checked)
              }
              required
            />
          </div>
          <label
            htmlFor="age-confirmation"
            className="text-white text-sm sm:text-base"
          >
            I confirm that I am 18+ years old and agree to all content guidelines, including no minors, animals, hate speech, or extreme violence. I understand I am responsible for appropriate content sharing. <span className="text-red-500">*</span>
          </label>
        </div>
      </div>
    </div>
  );
}
