import type { FeatureFlag, UserTier, FeatureDefinition } from '../types/features';

export const FEATURE_ACCESS: Record<UserTier, FeatureFlag[]> = {
  'free-nonauthenticated': [
    'STORY_EXPORT'
  ],
  'free-authenticated': [
    'STORY_EXPORT',
    'STORY_SAVING'
  ],
  'premium-authenticated': [
    'UNLIMITED_STORIES',
    'STORY_CONTINUATION',
    'STORY_EXPORT',
    'STORY_SAVING'
  ]
} as const;

export const FEATURES: FeatureDefinition[] = [
  {
    id: 'UNLIMITED_STORIES',
    title: 'Unlimited Story Generation',
    description: 'Generate as many stories as you want without monthly limits',
    premiumOnly: true
  },
  {
    id: 'STORY_CONTINUATION',
    title: 'Story Continuation',
    description: 'Continue and expand your generated stories',
    premiumOnly: true
  },
  {
    id: 'STORY_SAVING',
    title: 'Story Library',
    description: 'Save stories to your personal library',
    premiumOnly: false
  },
  {
    id: 'STORY_EXPORT',
    title: 'Story Export',
    description: 'Download and export your stories in various formats',
    premiumOnly: false
  }
] as const;

// Helper function to get feature details
export const getFeatureDetails = (featureId: FeatureFlag): FeatureDefinition => {
  const feature = FEATURES.find(f => f.id === featureId);
  if (!feature) {
    throw new Error(`Feature ${featureId} not found`);
  }
  return feature;
};
