export interface PlanFeature {
  name: string;
  description: string;
  included: boolean;
}

export interface Plan {
  id: string;
  name: string;
  description: string;
  price: number;
  interval: 'month' | 'year';
  features: PlanFeature[];
  popular?: boolean;
}

export const PREMIUM_PLANS: Plan[] = [
  {
    id: 'premium-monthly',
    name: 'Premium Monthly',
    description: 'Full access to all StoryCraft features',
    price: 9.99,
    interval: 'month',
    features: [
      {
        name: 'Unlimited Story Generation',
        description: 'Generate as many stories as you want',
        included: true,
      },
      {
        name: 'Story Continuation',
        description: 'Continue and expand your stories',
        included: true,
      },
      {
        name: 'Advanced Story Controls',
        description: 'Fine-tune your story generation',
        included: true,
      },
      {
        name: 'Priority Support',
        description: '24/7 priority customer support',
        included: true,
      },
    ],
  },
  {
    id: 'premium-yearly',
    name: 'Premium Yearly',
    description: 'Save 20% with annual billing',
    price: 95.88, // 7.99 per month
    interval: 'year',
    popular: true,
    features: [
      {
        name: 'Unlimited Story Generation',
        description: 'Generate as many stories as you want',
        included: true,
      },
      {
        name: 'Story Continuation',
        description: 'Continue and expand your stories',
        included: true,
      },
      {
        name: 'Advanced Story Controls',
        description: 'Fine-tune your story generation',
        included: true,
      },
      {
        name: 'Priority Support',
        description: '24/7 priority customer support',
        included: true,
      },
      {
        name: 'Early Access',
        description: 'Get early access to new features',
        included: true,
      },
    ],
  },
];
