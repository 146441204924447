import React from 'react';
import { Link } from 'wouter';
import { ChevronLeft } from 'lucide-react';
import { Button } from "@/components/ui/button";
import Footer from '@/components/app/Footer';
import { Header } from '@/components/app/Header';

const AccountSettingsGuidePage = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <main className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="mb-8">
          <Link href="/support">
            <a className="inline-flex items-center text-gray-400 hover:text-white transition-colors">
              <ChevronLeft className="w-4 h-4 mr-1" />
              Back to Support
            </a>
          </Link>
        </div>

        <h1 className="text-4xl font-serif mb-8">Managing Your Account Settings</h1>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-serif mb-4">Profile Settings</h2>
            <p className="text-gray-300 mb-4">
              Your profile settings can be accessed by clicking your avatar in the top right corner and selecting "Profile Settings".
              Here you can:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Update your profile picture</li>
              <li>Change your display name</li>
              <li>Edit your bio and interests</li>
              <li>Manage your email preferences</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Privacy Controls</h2>
            <p className="text-gray-300 mb-4">
              We take your privacy seriously. You can customize your privacy settings to control:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Who can view your stories</li>
              <li>Story visibility (public, private, or friends-only)</li>
              <li>Comment permissions</li>
              <li>Email notification preferences</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Account Security</h2>
            <p className="text-gray-300 mb-4">
              Keep your account secure by regularly updating your security settings:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Change your password</li>
              <li>Enable two-factor authentication</li>
              <li>Review active sessions</li>
              <li>Check login history</li>
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccountSettingsGuidePage;
