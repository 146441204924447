import React from 'react';
import { Link } from 'wouter';
import { ChevronLeft } from 'lucide-react';
import { Button } from "@/components/ui/button";
import Footer from '@/components/app/Footer';
import { Header } from '@/components/app/Header';

const StorySharingGuidePage = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <main className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="mb-8">
          <Link href="/support">
            <a className="inline-flex items-center text-gray-400 hover:text-white transition-colors">
              <ChevronLeft className="w-4 h-4 mr-1" />
              Back to Support
            </a>
          </Link>
        </div>

        <h1 className="text-4xl font-serif mb-8">Sharing Your Stories</h1>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-serif mb-4">Publishing Your Story</h2>
            <p className="text-gray-300 mb-4">
              Once you've created your story, you can share it with the world. Here's how:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Click the "Publish" button on your story draft</li>
              <li>Choose your story's visibility settings</li>
              <li>Add relevant tags to help others discover your story</li>
              <li>Write a compelling description</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Social Sharing</h2>
            <p className="text-gray-300 mb-4">
              Share your stories on social media platforms:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Use the social sharing buttons on your story page</li>
              <li>Copy and share your story's unique URL</li>
              <li>Share directly to Twitter, Facebook, or LinkedIn</li>
              <li>Embed your story on your blog or website</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Building Your Audience</h2>
            <p className="text-gray-300 mb-4">
              Tips for growing your readership:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Engage with other writers' stories</li>
              <li>Respond to comments on your stories</li>
              <li>Share updates regularly</li>
              <li>Join writing communities and challenges</li>
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default StorySharingGuidePage;
