import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { Button } from '../ui/button';
import { Receipt, ExternalLink } from 'lucide-react';
import { getPaymentHistory, type PaymentHistory as PaymentHistoryType } from '../../lib/api';
import { format } from 'date-fns';

export const PaymentHistory = () => {
  const { data: payments, isLoading } = useQuery({
    queryKey: ['payment-history'],
    queryFn: getPaymentHistory,
  });

  if (isLoading) {
    return (
      <Card className="bg-gray-800">
        <CardContent className="py-6">
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!payments?.length) {
    return (
      <Card className="bg-gray-800">
        <CardContent className="py-6">
          <div className="text-center text-gray-400">
            No payment history available.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="bg-gray-800">
      <CardHeader>
        <CardTitle className="text-gray-100 flex items-center gap-2">
          <Receipt className="h-5 w-5" />
          Payment History
        </CardTitle>
        <CardDescription className="text-gray-300">
          Your recent transactions
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow className="border-gray-700">
              <TableHead className="text-gray-300">Date</TableHead>
              <TableHead className="text-gray-300">Amount</TableHead>
              <TableHead className="text-gray-300">Status</TableHead>
              <TableHead className="text-gray-300">Receipt</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {payments.map((payment) => (
              <TableRow key={payment.id} className="border-gray-700">
                <TableCell className="text-gray-300">
                  {format(payment.created * 1000, 'MMM d, yyyy')}
                </TableCell>
                <TableCell className="text-gray-300">
                  {(payment.amount / 100).toFixed(2)} {payment.currency.toUpperCase()}
                </TableCell>
                <TableCell>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    payment.status === 'succeeded' ? 'bg-green-900 text-green-300' :
                    payment.status === 'failed' ? 'bg-red-900 text-red-300' :
                    'bg-yellow-900 text-yellow-300'
                  }`}>
                    {payment.status.charAt(0).toUpperCase() + payment.status.slice(1)}
                  </span>
                </TableCell>
                <TableCell>
                  {payment.receiptUrl && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="text-gray-300 hover:text-gray-100"
                      onClick={() => window.open(payment.receiptUrl, '_blank')}
                    >
                      <ExternalLink className="h-4 w-4" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
