'use client'

import React from 'react'
import { Link, useLocation } from "wouter";

export default function Footer() {
    const [, setLocation] = useLocation();

    return (
        <footer className="bg-[#1d1c1c] py-20 px-4">
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">
                <div className="text-center">
                    <ul className="space-y-2 text-gray-300">
                        <li className="cursor-pointer hover:text-white" onClick={() => setLocation("/about")}>About Us</li>
                        <li className="cursor-pointer hover:text-white" onClick={() => setLocation("/features")}>Features</li>
                        <li className="cursor-pointer hover:text-white" onClick={() => setLocation("/pricing")}>Pricing</li>
                        <li className="cursor-pointer hover:text-white" onClick={() => setLocation("/contact")}>Contact</li>
                    </ul>
                </div>
                
                <div className="text-center">
                    <ul className="space-y-2 text-gray-300">
                        <li className="cursor-pointer hover:text-white">
                            <a href="/#faq" className="hover:text-white">FAQ</a>
                        </li>
                        <li className="cursor-pointer hover:text-white">
                            <a href="/support" className="hover:text-white">Support</a>
                        </li>
                        <li className="cursor-pointer hover:text-white">
                            <a href="/content-policy" className="hover:text-white">Content Policy</a>
                        </li>
                        <li className="cursor-pointer hover:text-white">
                            <a href="/privacy-policy" className="hover:text-white">Privacy Policy</a>
                        </li>
                    </ul>
                </div>

                <div className="text-center">
                    <ul className="space-y-2 text-gray-300">
                        <li className="cursor-pointer hover:text-white">Twitter</li>
                        <li className="cursor-pointer hover:text-white">Discord</li>
                        <li className="cursor-pointer hover:text-white">Blog</li>
                        <li className="cursor-pointer hover:text-white">Newsletter</li>
                    </ul>
                </div>
            </div>
            <div className="text-center mt-12 text-gray-400 text-sm max-w-4xl mx-auto">
                All content on this website is 100% AI-generated and is purely fictional, and does not depict any real persons, places, or events.
            </div>
        </footer>
    )
}