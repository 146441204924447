import React from 'react';
import { useParams, useLocation } from "wouter";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Textarea } from "../ui/textarea";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Loader2 } from "lucide-react";
import { updateStory } from "../../lib/api";
import { queryClient } from "../../lib/queryClient";
import type { Story } from "../../../types/schema";
import { useToast } from "../../hooks/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

export default function EditStory() {
  const params = useParams();
  const [, setLocation] = useLocation();
  const { toast } = useToast();
  const storyId = params.id ? parseInt(params.id) : null;

  const { data: story, isLoading } = useQuery<Story>({
    queryKey: ["stories", storyId],
    queryFn: async () => {
      if (!storyId) throw new Error("Story ID is required");
      const stories = await queryClient.fetchQuery<Story[]>({ 
        queryKey: ["stories"],
        queryFn: async () => {
          const response = await fetch("/api/stories");
          if (!response.ok) throw new Error("Failed to fetch stories");
          return response.json();
        }
      });
      const found = stories.find((s) => String(s.id) === String(storyId));
      if (!found) throw new Error("Story not found");
      return found;
    },
    enabled: !!storyId,
  });

  const updateMutation = useMutation({
    mutationFn: (updates: { title: string; content: string }) => {
      if (!storyId) throw new Error("Story ID is required");
      return updateStory(storyId, updates);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      toast({
        title: "Success",
        description: "Story updated successfully",
      });
      setLocation("/profile");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to update story",
        variant: "destructive",
      });
    },
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background p-8">
        <Card className="max-w-4xl mx-auto">
          <CardContent className="p-6">
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!story) {
    return (
      <div className="min-h-screen bg-background p-8">
        <Card className="max-w-4xl mx-auto">
          <CardContent className="p-6">
            <div className="text-center py-8">Story not found</div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-8">
      <Card className="max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle>Edit Story</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Input
              value={story.title}
              onChange={(e) =>
                queryClient.setQueryData<Story>(
                  ["stories", storyId],
                  (old: Story | undefined) => ({ ...(old || story), title: e.target.value })
                )
              }
              placeholder="Story title"
            />
          </div>
          <div className="space-y-2">
            <Textarea
              value={story.content}
              onChange={(e) =>
                queryClient.setQueryData<Story>(
                  ["stories", storyId],
                  (old: Story | undefined) => ({ ...(old || story), content: e.target.value })
                )
              }
              className="min-h-[400px]"
              placeholder="Story content"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setLocation("/profile")}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                updateMutation.mutate({
                  title: story.title,
                  content: story.content,
                })
              }
              disabled={updateMutation.isPending}
            >
              Save Changes
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
