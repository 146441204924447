import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { CustomDialog } from "./ui/custom-dialog";
import { useToast } from "../hooks/use-toast";
import { useAuthStore } from "../store/auth";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "../lib/supabase";
import type { User } from "../types/user";

interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
  onRegisterClick?: () => void;
}

export function LoginDialog({ open, onClose, onRegisterClick }: LoginDialogProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isAutofilling, setIsAutofilling] = useState(false);
  const { toast } = useToast();
  const setUser = useAuthStore(state => state.setUser);
  const queryClient = useQueryClient();
  const autofillTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!open || !formRef.current) return;

    const form = formRef.current;
    const inputs = form.querySelectorAll('input');

    const handleValueChange = (input: HTMLInputElement) => {
      setIsAutofilling(true);
      if (autofillTimeoutRef.current) {
        clearTimeout(autofillTimeoutRef.current);
      }

      setFormData(prev => ({
        ...prev,
        [input.name]: input.value
      }));

      autofillTimeoutRef.current = setTimeout(() => {
        setIsAutofilling(false);
      }, 1000);
    };

    const observers = new Map();

    inputs.forEach(input => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          handleValueChange(input as HTMLInputElement);
        });
      });

      observer.observe(input, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true
      });

      observers.set(input, observer);

      input.addEventListener('input', () => handleValueChange(input as HTMLInputElement));
      input.addEventListener('change', () => handleValueChange(input as HTMLInputElement));
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
      if (autofillTimeoutRef.current) {
        clearTimeout(autofillTimeoutRef.current);
      }
    };
  }, [open]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const mapSupabaseUser = async (supabaseUser: any): Promise<User> => {
    const response = await fetch(`/api/users/${supabaseUser.id}`);
    if (!response.ok) {
      throw new Error("Failed to get user data");
    }
    const userData = await response.json();

    return {
      id: supabaseUser.id,
      username: userData.username || formData.email.split('@')[0],
      role: userData.role || "user",
      ageVerified: userData.ageVerified || false,
      createdAt: supabaseUser.created_at ? new Date(supabaseUser.created_at).getTime() : undefined,
      isPremium: userData.isPremium || false
    };
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading || isAutofilling) return;

    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });

      if (error) throw error;

      const mappedUser = await mapSupabaseUser(data.user);
      setUser(mappedUser);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      onClose();
      toast({ 
        title: "Success", 
        description: "Successfully logged in" 
      });
    } catch (error) {
      console.error("Login failed:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Login failed",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLink = async () => {
    if (loading || isAutofilling) return;

    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: formData.email,
        options: {
          emailRedirectTo: window.location.origin,
        },
      });

      if (error) throw error;

      toast({
        title: "Check your email",
        description: "We've sent you a magic link to log in",
      });
      onClose();
    } catch (error) {
      console.error("Magic link failed:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to send magic link",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog 
      open={open} 
      onClose={onClose}
      preventClose={isAutofilling || loading}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full">
          <div className="p-6 space-y-4 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl">
              Sign in to your account
            </h1>
            <div className="space-y-4 md:space-y-6">
              <form ref={formRef} onSubmit={handleLogin} className="space-y-4 md:space-y-6">
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder=""
                    required
                    autoComplete="email"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder=""
                    required
                    autoComplete="current-password"
                  />
                </div>
                <button
                  type="submit"
                  disabled={loading || isAutofilling}
                  className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? "Signing in..." : "Sign in"}
                </button>
              </form>

              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t border-gray-600" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-400 bg-[#1A1A1A]">Or continue with</span>
                </div>
              </div>

              <div className="space-y-2">
                <p className="text-sm text-gray-400 text-center">
                  Don't want to use a password? Fill in your email and we'll email you a magic login link instead.
                </p>
                <button
                  type="button"
                  onClick={handleMagicLink}
                  disabled={loading || isAutofilling || !formData.email}
                  className="w-full text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? "Sending..." : "Send Magic Link"}
                </button>
              </div>

              <p className="text-sm font-light text-gray-400">
                Don't have an account yet?{" "}
                <button
                  type="button"
                  onClick={onRegisterClick}
                  className="font-medium text-gray-200 hover:underline"
                >
                  Sign up
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}