import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../ui/dialog';
import { Button } from '../ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Badge } from '../ui/badge';
import { Check, Sparkles } from 'lucide-react';
import { PREMIUM_PLANS, type Plan } from '../../config/plans';
import { createPayment } from '../../lib/nowpayments';
import { useToast } from '../../hooks/use-toast';
import { cn } from '../../lib/utils';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

const PAYMENT_OPTIONS = [
  { value: 'USD', label: 'Credit Card (USD)' },
  { value: 'BTC', label: 'Bitcoin (BTC)' },
  { value: 'ETH', label: 'Ethereum (ETH)' },
  { value: 'USDT', label: 'Tether (USDT)' },
] as const;

interface PremiumDialogProps {
  open: boolean;
  onClose: () => void;
}

export const PremiumDialog: React.FC<PremiumDialogProps> = ({ open, onClose }) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');

  const handleSubscribe = async (plan: Plan) => {
    setLoading(true);
    try {
      const payment = await createPayment(plan, {
        planId: plan.id,
        amount: plan.price,
        payCurrency: selectedCurrency,
      });
      window.location.href = payment.payment_url;
    } catch (error) {
      console.error('Payment creation failed:', error);
      toast({
        title: 'Error',
        description: 'Failed to create payment. Please try again.',
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-2xl">
            <Sparkles className="h-6 w-6 text-yellow-500" />
            Upgrade to Premium
          </DialogTitle>
          <DialogDescription>
            Unlock unlimited story generation and premium features
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {PREMIUM_PLANS.map((plan) => (
            <Card
              key={plan.id}
              className={cn(
                'relative',
                plan.popular && 'border-2 border-yellow-500'
              )}
            >
              {plan.popular && (
                <Badge
                  className="absolute -top-2 left-1/2 -translate-x-1/2 bg-yellow-500"
                  variant="secondary"
                >
                  Most Popular
                </Badge>
              )}
              <CardHeader>
                <CardTitle>{plan.name}</CardTitle>
                <CardDescription>{plan.description}</CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="text-3xl font-bold">
                  ${plan.price}
                  <span className="text-sm font-normal text-muted-foreground">
                    /{plan.interval}
                  </span>
                </div>
                <ul className="space-y-2">
                  {plan.features.map((feature) => (
                    <li key={feature.name} className="flex items-start gap-2">
                      <Check className="h-5 w-5 text-green-500 shrink-0" />
                      <div>
                        <p className="font-medium">{feature.name}</p>
                        <p className="text-sm text-muted-foreground">
                          {feature.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="flex flex-col gap-4">
                <Select value={selectedCurrency} onValueChange={setSelectedCurrency}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select payment method" />
                  </SelectTrigger>
                  <SelectContent>
                    {PAYMENT_OPTIONS.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button
                  className="w-full"
                  variant={plan.popular ? 'premium' : 'default'}
                  onClick={() => handleSubscribe(plan)}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center gap-2">
                      <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                      Processing...
                    </div>
                  ) : (
                    `Subscribe for ${plan.price} USD`
                  )}
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>

        <div className="text-center text-sm text-muted-foreground">
          Prices in USD. Cancel anytime. 30-day money-back guarantee.
        </div>
      </DialogContent>
    </Dialog>
  );
};
