// Maximum lengths for different input types
export const MAX_LENGTHS = {
  STORY_DESCRIPTION: 2000,
  CHARACTER_NAME: 50,
  CHARACTER_APPEARANCE: 200,
  CHARACTER_BACKGROUND: 1000,
  SCENE_DESCRIPTION: 2000
} as const;

// AI-related keywords to filter out
export const AI_KEYWORDS = [
  'system prompt',
  'assistant',
  'ignore previous',
  'ignore above',
  'disregard',
  'instructions:',
  '/prompt',
  '/system',
  '/assistant',
  '/user',
  'context:',
  'settings:',
  'configuration:',
  'parameters:',
  'output:',
  'model:',
  'temperature:',
  'tokens:',
  'max_tokens:',
  'openai',
  'gpt',
  'chatgpt',
  'language model',
  'ai model',
  'prompt engineering'
];

// Regex to match common prompt injection patterns
export const PROMPT_INJECTION_PATTERNS = [
  /\{[\s\S]*\}/,  // JSON-like structures
  /\[system\][\s\S]*\[\/system\]/i,  // System blocks
  /\[prompt\][\s\S]*\[\/prompt\]/i,  // Prompt blocks
  /\[context\][\s\S]*\[\/context\]/i,  // Context blocks
  /\[instruction\][\s\S]*\[\/instruction\]/i,  // Instruction blocks
  /```[\s\S]*```/,  // Code blocks
  /<\/?[a-z]+>/i,  // HTML-like tags
];

import type { StoryGeneration } from "../../types/schema";

/**
 * Validates and sanitizes input text
 * @param text The input text to validate
 * @param maxLength Maximum allowed length
 * @returns Sanitized text or null if invalid
 */
export function validateAndSanitizeInput(text: string, maxLength: number): string | null {
  // Check length
  if (!text || text.length > maxLength) {
    return null;
  }

  // Convert to lowercase for case-insensitive checks
  const lowerText = text.toLowerCase();

  // Check for AI keywords
  if (AI_KEYWORDS.some(keyword => lowerText.includes(keyword.toLowerCase()))) {
    return null;
  }

  // Check for prompt injection patterns
  if (PROMPT_INJECTION_PATTERNS.some(pattern => pattern.test(text))) {
    return null;
  }

  // Basic sanitization
  return text
    .trim()
    .replace(/[\u200B-\u200D\uFEFF]/g, '') // Remove zero-width characters
    .replace(/\s+/g, ' '); // Normalize whitespace
}

/**
 * Validates all story inputs
 */
export function validateStoryInputs(data: Partial<StoryGeneration>): {
  isValid: boolean;
  sanitizedData: Partial<StoryGeneration>;
  errors: string[];
} {
  const errors: string[] = [];
  const sanitizedData: Partial<StoryGeneration> = { ...data };

  // Check required fields
  if (!data.explicitLevel) {
    errors.push('Explicit level is required');
  }
  if (!data.narrativeStyle) {
    errors.push('Narrative style is required');
  }
  if (!data.scene) {
    errors.push('Scene description is required');
  }
  if (!data.characters?.length) {
    errors.push('At least one character is required');
  }
  if (!data.ageConfirmed) {
    errors.push('Age confirmation is required');
  }

  // Validate scene description if present
  if (data.scene) {
    const sanitizedScene = validateAndSanitizeInput(data.scene, MAX_LENGTHS.SCENE_DESCRIPTION);
    if (!sanitizedScene) {
      errors.push(`Scene description is invalid or too long (max ${MAX_LENGTHS.SCENE_DESCRIPTION} characters)`);
    } else {
      sanitizedData.scene = sanitizedScene;
    }
  }

  // Validate characters if present
  if (data.characters?.length) {
    let hasInvalidCharacter = false;
    sanitizedData.characters = data.characters.map((char, index) => {
      if (!char.name || !char.gender) {
        errors.push(`Character ${index + 1} must have both name and gender`);
        hasInvalidCharacter = true;
      }
      return {
        ...char,
        name: char.name ? validateAndSanitizeInput(char.name, MAX_LENGTHS.CHARACTER_NAME) || char.name : char.name,
        appearance: char.appearance ? validateAndSanitizeInput(char.appearance, MAX_LENGTHS.CHARACTER_APPEARANCE) || char.appearance : char.appearance,
        backgroundStory: char.backgroundStory ? validateAndSanitizeInput(char.backgroundStory, MAX_LENGTHS.CHARACTER_BACKGROUND) || char.backgroundStory : char.backgroundStory,
        gender: char.gender || ''
      };
    });
  }

  return {
    isValid: errors.length === 0,
    sanitizedData,
    errors
  };
}
