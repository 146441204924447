import { useAuthStore } from '../store/auth';
import { FEATURE_ACCESS, FEATURES, getFeatureDetails } from '../config/featureFlags';
import type { FeatureFlag, UserTier, FeatureDefinition } from '../types/features';

export function useFeatureFlag() {
  const user = useAuthStore(state => state.user);
  
  // Determine user tier
  const getUserTier = (): UserTier => {
    if (!user) return 'free-nonauthenticated';
    if (user.isPremium) return 'premium-authenticated';
    return 'free-authenticated';
  };

  // Check if feature is available for current user
  const hasFeature = (feature: FeatureFlag): boolean => {
    const tier = getUserTier();
    return FEATURE_ACCESS[tier].includes(feature);
  };

  // Get all available features for current user
  const getAvailableFeatures = (): FeatureDefinition[] => {
    const tier = getUserTier();
    const availableFeatureIds = FEATURE_ACCESS[tier];
    return availableFeatureIds.map(getFeatureDetails);
  };

  // Get features that would be unlocked by upgrading
  const getUpgradeFeatures = (): FeatureDefinition[] => {
    const currentTier = getUserTier();
    if (currentTier === 'premium-authenticated') return [];

    const premiumFeatures = FEATURE_ACCESS['premium-authenticated'];
    const currentFeatures = FEATURE_ACCESS[currentTier];
    
    return premiumFeatures
      .filter(feature => !currentFeatures.includes(feature))
      .map(getFeatureDetails);
  };

  // Check if a feature requires premium
  const isFeaturePremium = (feature: FeatureFlag): boolean => {
    return getFeatureDetails(feature).premiumOnly;
  };

  return {
    hasFeature,
    getAvailableFeatures,
    getUpgradeFeatures,
    isFeaturePremium,
    userTier: getUserTier()
  };
}
