import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from "react";
import { useLocation } from "wouter";
import { Button } from "../ui/button";
import { Card, CardContent } from "../ui/card";
import { Alert, AlertDescription } from "../ui/alert";
import { Progress } from "../ui/progress";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CharacterCustomization from "../CharacterCustomization";
import StoryPlot from "../StoryPlot";
import ExplicitLevelSelection from "../ExplicitLevelSelection";
import StoryDisplay from "../StoryDisplay";
import LoadingScreen from "../LoadingScreen";
import { generateStory } from "../../lib/api";
import { useToast } from "../../hooks/use-toast";
import type { StoryGeneration, Story } from "../../../types/schema";
import { Header } from "./Header";
import Footer from "./Footer";
import { validateStoryInputs } from '../../lib/validation';
import { GenerationLimits } from "../GenerationLimits";
import { LoginDialog } from '../LoginDialog';
import { CustomRegisterDialog } from '../CustomRegisterDialog';
import jsPDF from 'jspdf';

const steps = ["Level of Explicitness", "Characters", "Story Plot", "Generate"];

interface GenerateStoryResponse {
  content: string;
  remainingGenerations?: number;
  message?: string;
  savedStory?: Story | null;
}

export default function StoryWizard() {
  const [currentStep, setCurrentStep] = useState(0);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [storyData, setStoryData] = useState<Partial<StoryGeneration>>({
    explicitLevel: "explicit",
    ageConfirmed: false,
    characters: [],
    remainingGenerations: 3, // Default for free users
    isAuthenticated: false,
    user: null
  });
  const [generatedStory, setGeneratedStory] = useState<string>("");
  const [, setLocation] = useLocation();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const generateMutation = useMutation<GenerateStoryResponse, Error, StoryGeneration>({
    mutationFn: generateStory,
    onSuccess: (data) => {
      if (data.content) {
        setGeneratedStory(data.content);
        setStoryData(prev => ({
          ...prev,
          remainingGenerations: data.remainingGenerations,
          savedStory: data.savedStory
        }));
        setCurrentStep(currentStep + 1);
        // Invalidate stories query to refresh Profile page
        queryClient.invalidateQueries({ queryKey: ['stories'] });
      } else {
        toast({
          title: "Error",
          description: "Failed to generate story. Please try again.",
          variant: "destructive",
        });
      }
    },
    onError: (error) => {
      console.error('Story generation error:', error);
      // Don't show login error for free users
      if (error.message.includes("must be logged in") && !storyData.isAuthenticated) {
        // Still set the story if we have it
        if (error.message.includes("to save stories") && error instanceof Error && 'content' in error) {
          setGeneratedStory((error as any).content);
          setCurrentStep(currentStep + 1);
          return;
        }
      }
      
      toast({
        title: "Error",
        description: error.message || "Failed to generate story. Please try again.",
        variant: "destructive",
      });
    },
  });

  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      // Validate all inputs before sending to AI
      const validation = validateStoryInputs(storyData);
      
      if (!validation.isValid) {
        toast({
          title: "Invalid Input",
          description: validation.errors.join('\n'),
          variant: "destructive"
        });
        return;
      }

      // Update storyData with sanitized values while preserving types
      setStoryData(prev => ({
        ...prev,
        ...validation.sanitizedData
      }));

      // Generate the story
      generateMutation.mutate(validation.sanitizedData as StoryGeneration);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      setLocation("/");
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const updateStoryData = (data: Partial<StoryGeneration>) => {
    setStoryData((prev: Partial<StoryGeneration>) => {
      // Create a new state object with the existing data
      const newState = { ...prev };
      
      // Special handling for characters array
      if ('characters' in data) {
        newState.characters = [...(data.characters || [])];
      }
      
      // Handle all other fields
      Object.entries(data).forEach(([key, value]) => {
        if (key !== 'characters') {
          (newState as any)[key] = value;
        }
      });
      
      return newState;
    });
  };

  const handleExport = (format: 'pdf' | 'txt') => {
    if (!generatedStory) return;
    
    try {
      if (format === 'pdf') {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 20;
        const textWidth = pageWidth - 2 * margin;
        
        // Set dark background for each page
        doc.setFillColor(18, 18, 18); // Dark background color
        doc.rect(0, 0, pageWidth, pageHeight, 'F');
        
        // Split text into paragraphs and clean them
        const paragraphs = generatedStory
          .split('\n')
          .map(p => p.trim())
          .filter(p => p.length > 0);
        
        let y = margin + 10; // Start a bit lower from the top
        const lineHeight = 6;
        
        // Set text color to light and font size
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(10);
        
        paragraphs.forEach((paragraph, index) => {
          // Split paragraph into lines that fit within the page width
          const lines = doc.splitTextToSize(paragraph, textWidth);
          
          // Check if we need a new page
          if (y + lines.length * lineHeight > pageHeight - margin) {
            doc.addPage();
            // Set dark background for new page
            doc.setFillColor(18, 18, 18);
            doc.rect(0, 0, pageWidth, pageHeight, 'F');
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(10);
            y = margin + 10;
          }
          
          // Add lines to the page
          doc.text(lines, margin, y);
          y += lines.length * lineHeight + (index < paragraphs.length - 1 ? 8 : 0); // Add extra spacing between paragraphs, but not after the last one
        });
        
        doc.save('story.pdf');
      } else {
        const element = document.createElement('a');
        const file = new Blob([generatedStory], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = 'story.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

      toast({
        title: "Success",
        description: `Story exported as ${format.toUpperCase()}`,
      });
    } catch (error) {
      console.error("Export error:", error);
      toast({
        title: "Error",
        description: `Failed to export story as ${format.toUpperCase()}`,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-black text-white">
      <Helmet>
        <title>{`${steps[currentStep]} - Story Wizard`}</title>
      </Helmet>
      <div className="fixed top-0 left-0 right-0 z-50 bg-black">
        <Header />
      </div>
      <main className="flex-1 w-full pt-20 px-8 pb-32">
        <Card className="max-w-4xl mx-auto p-6 bg-black border-gray-800 mb-8">
          <CardContent className="pt-6">
            {/* Show generation limits for free users */}
            <GenerationLimits 
              remainingGenerations={storyData.remainingGenerations ?? 3}
              maxGenerations={3}
              isLoggedIn={!!storyData.isAuthenticated}
              setShowRegister={setShowRegister}
            />
            
            {/* Step content */}
            <div className="space-y-8">
              {currentStep === 0 && (
                <ExplicitLevelSelection
                  value={storyData}
                  onChange={updateStoryData}
                />
              )}
              {currentStep === 1 && (
                <CharacterCustomization
                  value={storyData}
                  onChange={updateStoryData}
                />
              )}
              {currentStep === 2 && (
                <StoryPlot
                  value={storyData}
                  onChange={updateStoryData}
                />
              )}
              {currentStep === 3 && generateMutation.isPending && (
                <LoadingScreen explicitLevel={storyData.explicitLevel || "moderate"} />
              )}
              {currentStep === steps.length && (
                <StoryDisplay 
                  story={generatedStory} 
                  storyData={storyData as Required<StoryGeneration>}
                  isLoggedIn={!!storyData.isAuthenticated}
                  isPremium={!!storyData.user?.isPremium}
                  onExport={handleExport}
                />
              )}
            </div>
            <div className="flex justify-between mt-6">
              <Button
                variant="outline"
                onClick={handleBack}
              >
                {currentStep === 0 ? "Cancel" : "Back"}
              </Button>
              {currentStep < steps.length && (
                <Button
                  onClick={handleNext}
                  disabled={generateMutation.isPending || (currentStep === 0 && !storyData.ageConfirmed)}
                >
                  {currentStep === steps.length - 1 ? "Generate Story" : "Next"}
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      </main>
      <div className="w-full mt-auto">
        <Footer />
      </div>
      <CustomRegisterDialog 
        open={showRegister}
        onClose={() => setShowRegister(false)}
        onLoginClick={() => {
          setShowRegister(false);
          setShowLogin(true);
        }}
      />
      <LoginDialog 
        open={showLogin}
        onClose={() => setShowLogin(false)}
        onRegisterClick={() => {
          setShowLogin(false);
          setShowRegister(true);
        }}
      />
    </div>
  );
}
