import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Switch, Route, useLocation } from "wouter";
import "./index.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./lib/queryClient";
import { Toaster } from "./components/ui/toaster";
import { ThemeProvider } from "./components/theme-provider";
import StoryWizard from "./components/app/StoryWizard";
import Profile from "./components/app/Profile";
import EditStory from "./components/app/EditStory";
import AgeVerifiedRoute from "./components/AgeVerifiedRoute";
import HomePage from "client/pages";
import ContentPolicy from "client/pages/content-policy";
import PrivacyPolicy from "client/pages/privacy-policy";
import Support from "client/pages/support";
import HowToGenerateStories from "client/pages/support/how-to-generate-stories";
import StorySharing from "client/pages/support/story-sharing";
import CommunityGuidelines from "client/pages/support/community-guidelines";
import AccountSettings from "client/pages/support/account-settings";
import Troubleshooting from "client/pages/support/troubleshooting";
import { HelmetProvider } from 'react-helmet-async';
import { supabase } from "./lib/supabase";
import { useAuthStore } from "./store/auth";

function App() {
  const [, setLocation] = useLocation();
  const setUser = useAuthStore(state => state.setUser);

  useEffect(() => {
    // Initialize auth store
    useAuthStore.getState().initialize();
  }, []);

  useEffect(() => {
    // Handle magic link authentication
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN') {
        if (session?.user) {
          // Get user metadata from session
          const metadata = session.user.user_metadata;
          
          const user = {
            id: session.user.id,
            username: metadata?.username || session.user.email?.split('@')[0] || '',
            role: metadata?.role || "user",
            ageVerified: metadata?.ageVerified || false,
            createdAt: session.user.created_at ? new Date(session.user.created_at).getTime() : undefined,
            isPremium: metadata?.isPremium || false
          };
          
          setUser(user);
          
          // Redirect to profile page after successful sign in
          setLocation('/profile');
        }
      }
    });
  }, [setUser, setLocation]);

  return (
    <ThemeProvider defaultTheme="system" storageKey="storycraft-theme">
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <div className="min-h-screen bg-background text-foreground">
            <Switch>
              <Route path="/" component={HomePage} />
              <Route path="/content-policy" component={ContentPolicy} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/support" component={Support} />
              <Route path="/support/how-to-generate-stories" component={HowToGenerateStories} />
              <Route path="/support/story-sharing" component={StorySharing} />
              <Route path="/support/community-guidelines" component={CommunityGuidelines} />
              <Route path="/support/account-settings" component={AccountSettings} />
              <Route path="/support/troubleshooting" component={Troubleshooting} />
              <Route path="/create">
                {() => (
                  <AgeVerifiedRoute>
                    <StoryWizard />
                  </AgeVerifiedRoute>
                )}
              </Route>
              <Route path="/profile" component={Profile} />
              <Route path="/stories/:id/edit" component={EditStory} />
            </Switch>
            <Toaster />
          </div>
        </QueryClientProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
