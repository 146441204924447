import React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Button } from "../components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { verifyAge } from "../lib/api";
import { useToast } from "../hooks/use-toast";
import { AlertTriangle } from "lucide-react";

interface AgeVerificationProps {
  open: boolean;
  onVerified: () => void;
  onClose: () => void;
}

export default function AgeVerification({
  open,
  onVerified,
  onClose,
}: AgeVerificationProps) {
  const { toast } = useToast();

  const verifyMutation = useMutation({
    mutationFn: verifyAge,
    onSuccess: () => {
      onVerified();
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to verify. Please try again.",
        variant: "destructive",
      });
    },
  });

  return (
    <AlertDialog open={open}>
      <AlertDialogContent className="bg-gray-900 border-gray-800 p-4 sm:p-6 max-w-lg mx-auto">
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle className="text-center flex justify-center items-center gap-2 text-red-500 text-lg sm:text-xl">
            <AlertTriangle className="h-5 w-5" />
            Content Warning
          </AlertDialogTitle>
          <AlertDialogDescription className="text-sm sm:text-base leading-relaxed space-y-3 text-gray-300">
            <p>This website may contain mature themes including explicit content, violence, and potentially disturbing material.</p>
            
            <p>By proceeding, you confirm that:</p>
            <ul className="list-disc pl-4 sm:pl-6 space-y-1">
              <li>You are 18 years or older</li>
              <li>You will not create or share content involving minors or animals</li>
              <li>You will not promote hate speech, discrimination, or extreme violence</li>
              <li>You understand that all content must be shared only with consenting adults</li>
            </ul>

            <p className="text-xs sm:text-sm text-gray-400">You are solely responsible for the content you create and share. Violations may result in immediate account suspension and legal action where applicable.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="sm:mt-4">
          <div className="w-full flex justify-center gap-3 sm:gap-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => verifyMutation.mutate()}
              disabled={verifyMutation.isPending}
              className="bg-red-600 hover:bg-red-700"
            >
              I confirm I am 18+, and I agree
            </Button>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
