import { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { useQuery } from "@tanstack/react-query";
import AgeVerification from "./AgeVerification";
import { supabase } from "@/lib/supabase";

interface AgeVerifiedRouteProps {
  children: React.ReactNode;
}

export default function AgeVerifiedRoute({ children }: AgeVerifiedRouteProps) {
  const [, setLocation] = useLocation();
  const [showAgeVerification, setShowAgeVerification] = useState(false);

  // Check if user is age verified
  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return null;

      const response = await fetch("https://storycraft-kv.quince-range3014.workers.dev/api/auth/me", {
        headers: {
          "Authorization": `Bearer ${session.access_token}`
        }
      });
      if (!response.ok) return null;
      return response.json();
    },
  });

  // Check session age verification
  const { data: session } = useQuery({
    queryKey: ["session"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return null;

      const response = await fetch("https://storycraft-kv.quince-range3014.workers.dev/api/auth/session", {
        headers: {
          "Authorization": `Bearer ${session.access_token}`
        }
      });
      if (!response.ok) return null;
      return response.json();
    },
  });

  useEffect(() => {
    // If user is logged in and age verified, allow access
    if (user?.ageVerified) return;

    // If session is age verified, allow access
    if (session?.ageVerified) return;

    // Otherwise, show age verification dialog
    setShowAgeVerification(true);
  }, [user, session]);

  if (showAgeVerification) {
    return (
      <AgeVerification
        open={showAgeVerification}
        onVerified={() => setShowAgeVerification(false)}
        onClose={() => setLocation("/")}
      />
    );
  }

  return <>{children}</>;
}
