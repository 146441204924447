import React from 'react';
import { useState } from "react";
import { Card } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { ScrollArea } from "../components/ui/scroll-area";
import { useToast } from "../hooks/use-toast";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  Share2,
  Save,
  RefreshCw,
  Download,
  FileText,
} from "lucide-react";
import jsPDF from 'jspdf';
import type { StoryGeneration } from "@/../../types/schema";
import { LoginDialog } from "./LoginDialog";
import { continueStory } from "../lib/api";
import { moderateContent } from '../lib/moderation';

interface StoryDisplayProps {
  story: string;
  storyData: StoryGeneration;
  isPremium?: boolean;
  isLoggedIn?: boolean;
  onExport?: (format: 'pdf' | 'txt') => void;
}

export default function StoryDisplay({ 
  story, 
  storyData,
  isPremium = false, 
  isLoggedIn = false,
  onExport 
}: StoryDisplayProps) {
  const [showLogin, setShowLogin] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [continuedStory, setContinuedStory] = useState<string>("");
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Only handle save functionality
  const handleSaveStory = async () => {
    if (!isLoggedIn) {
      setShowLogin(true);
      toast({
        title: "Login Required",
        description: "Please log in to save stories",
        variant: "default"
      });
      return;
    }

    try {
      const response = await fetch("/api/stories", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          title: `Story - ${new Date().toLocaleDateString()}`,
          content: story,
          genre: storyData.genre?.[0] || null,
          characters: storyData.characters,
          scene: storyData.scene,
          setting: storyData.setting,
          location: storyData.location,
          sexActs: storyData.sexActs,
          narrativeStyle: storyData.narrativeStyle,
          explicitLevel: storyData.explicitLevel,
          isPrivate: true
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save story");
      }

      queryClient.invalidateQueries({ queryKey: ["stories"] });
      toast({
        title: "Success",
        description: "Story saved successfully!",
      });
    } catch (error) {
      console.error("Save error:", error);
      toast({
        title: "Error",
        description: "Failed to save story. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Handle continue story functionality
  const handleContinueStory = async (explicitLevel: "more" | "less" | "same") => {
    if (!isPremium || !isLoggedIn) {
      setShowLogin(true);
      toast({
        title: "Premium Feature",
        description: "Continue story is a premium feature. Please upgrade to continue.",
        variant: "destructive",
      });
      return;
    }

    setIsGenerating(true);
    try {
      const result = await continueStory(
        story,
        explicitLevel,
        storyData,
        `Story - ${new Date().toLocaleDateString()}`
      );

      if (result.content) {
        setContinuedStory(result.content);
      } else {
        throw new Error("Failed to continue story");
      }
    } catch (error) {
      console.error("Continue story error:", error);
      toast({
        title: "Error",
        description: "Failed to continue story. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleExportStory = (format: 'pdf' | 'txt') => {
    if (onExport) {
      onExport(format);
      return;
    }

    try {
      if (format === 'pdf') {
        const doc = new jsPDF();
        doc.text(story, 10, 10);
        doc.save('story.pdf');
      } else {
        const element = document.createElement('a');
        const file = new Blob([story], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = 'story.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        URL.revokeObjectURL(element.href);
      }

      toast({
        title: "Success",
        description: `Story exported as ${format.toUpperCase()}`,
      });
    } catch (error) {
      console.error("Export error:", error);
      toast({
        title: "Error",
        description: `Failed to export story as ${format.toUpperCase()}`,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      {/* Story Display */}
      <Card className="p-6 bg-zinc-900 border-gray-800">
        <ScrollArea className="h-[60vh]">
          <div className="prose prose-invert max-w-none">
            {story && (
              <div 
                className="whitespace-pre-line leading-relaxed text-lg text-gray-100"
                style={{ 
                  textAlign: 'justify', 
                  textJustify: 'inter-word',
                  lineHeight: '2',
                  letterSpacing: '0.01em'
                }}
                dangerouslySetInnerHTML={{ 
                  __html: story.split('\n').map(paragraph => 
                    paragraph.trim() ? `<p class="mb-6 px-4">${paragraph}</p>` : ''
                  ).join('') 
                }} 
              />
            )}
            {continuedStory && (
              <div className="mt-8 pt-8 border-t border-gray-700">
                <div 
                  className="whitespace-pre-line leading-relaxed text-lg text-gray-100"
                  style={{ 
                    textAlign: 'justify', 
                    textJustify: 'inter-word',
                    lineHeight: '2',
                    letterSpacing: '0.01em'
                  }}
                  dangerouslySetInnerHTML={{ 
                    __html: continuedStory.split('\n').map(paragraph => 
                      paragraph.trim() ? `<p class="mb-6 px-4">${paragraph}</p>` : ''
                    ).join('') 
                  }} 
                />
              </div>
            )}
          </div>
        </ScrollArea>
      </Card>

      {/* Action Buttons */}
      <div className="flex flex-wrap gap-2">
        {/* Export buttons always available */}
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={() => onExport?.('pdf')}
          >
            <FileText className="w-4 h-4 mr-2" />
            Export PDF
          </Button>
          <Button
            variant="outline"
            onClick={() => onExport?.('txt')}
          >
            <Download className="w-4 h-4 mr-2" />
            Export TXT
          </Button>
        </div>

        {/* Premium features only for logged-in premium users */}
        {isPremium && isLoggedIn && (
          <div className="flex gap-2 ml-auto">
            <Button
              variant="outline"
              onClick={() => handleContinueStory('same')}
              disabled={isGenerating}
            >
              <RefreshCw className={`w-4 h-4 mr-2 ${isGenerating ? 'animate-spin' : ''}`} />
              Continue Story
            </Button>
            <Button
              variant="outline"
              onClick={handleSaveStory}
            >
              <Save className="w-4 h-4 mr-2" />
              Save Story
            </Button>
            <Button variant="outline">
              <Share2 className="w-4 h-4 mr-2" />
              Share
            </Button>
          </div>
        )}

        {/* Save button for logged-in non-premium users */}
        {isLoggedIn && !isPremium && (
          <div className="flex gap-2 ml-auto">
            <Button
              variant="outline"
              onClick={handleSaveStory}
            >
              <Save className="w-4 h-4 mr-2" />
              Save Story
            </Button>
          </div>
        )}
      </div>

      <LoginDialog 
        open={showLogin} 
        onClose={() => setShowLogin(false)}
      />
    </div>
  );
}
