import type { Plan } from '../config/plans';

interface NOWPaymentsResponse {
  payment_id: string;
  payment_status: string;
  pay_address: string;
  price_amount: number;
  price_currency: string;
  pay_amount: number;
  pay_currency: string;
  order_id: string;
  order_description: string;
  ipn_callback_url: string;
  created_at: string;
  updated_at: string;
  purchase_id: string;
  payment_url: string;
}

export interface PaymentOptions {
  planId: string;
  amount: number;
  payCurrency?: string; // The currency user wants to pay in (e.g., 'BTC', 'ETH', 'USD', 'EUR')
}

export async function createPayment(plan: Plan, options: PaymentOptions) {
  const response = await fetch(`/payments/create`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      planId: plan.id,
      amount: plan.price,
      currency: 'USD', // We price everything in USD
      payCurrency: options.payCurrency || 'USD', // Default to USD if not specified
      orderId: `order_${Date.now()}`,
      orderDescription: `StoryCraft ${plan.name} Subscription`
    }),
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to create payment' }));
    throw new Error(data.error || 'Failed to create payment');
  }

  return response.json() as Promise<NOWPaymentsResponse>;
}

export async function verifyPayment(paymentId: string) {
  const response = await fetch(`/payments/verify/${paymentId}`, {
    credentials: 'include',
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to verify payment' }));
    throw new Error(data.error || 'Failed to verify payment');
  }

  return response.json();
}

export async function getPaymentHistory() {
  const response = await fetch(`/payments/history`, {
    credentials: 'include',
  });

  if (!response.ok) {
    const data = await response.json().catch(() => ({ error: 'Failed to get payment history' }));
    throw new Error(data.error || 'Failed to get payment history');
  }

  return response.json();
}
