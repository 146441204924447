import React, { useState } from 'react';
import { Story, StoryGeneration } from '../../../types/schema';
import { useToast } from '../../hooks/use-toast';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { ScrollArea } from '../ui/scroll-area';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { Download, FileType, RefreshCw } from 'lucide-react';
import { downloadStoryAsPDF, downloadStoryAsTXT } from '../../lib/download-utils';
import { continueStory } from '../../lib/api';

interface StoryDialogProps {
  story: Story;
  open: boolean;
  onClose: () => void;
  isPremium: boolean;
}

export const StoryDialog: React.FC<StoryDialogProps> = ({
  story,
  open,
  onClose,
  isPremium = false,
}) => {
  const [generatingLevel, setGeneratingLevel] = useState<'more' | 'less' | 'same' | null>(null);
  const [continuation, setContinuation] = useState<string>("");
  const { toast } = useToast();

  const handleContinue = async (explicitLevel: 'more' | 'less' | 'same') => {
    if (!isPremium) {
      toast({
        title: "Premium Feature",
        description: "Please upgrade to premium to continue stories",
        variant: "default"
      });
      return;
    }

    setGeneratingLevel(explicitLevel);
    try {
      // Convert story data to StoryGeneration type
      const storyData: StoryGeneration = {
        genre: story.genre ? [story.genre] : [],
        setting: story.setting || "",
        characters: (typeof story.characters === 'string' 
          ? JSON.parse(story.characters || '[]') 
          : story.characters || []
        ).map((char: { name: string; role: string; description: string }) => ({
          name: char.name,
          gender: "unspecified",
          appearance: char.description,
          personality: [],
          backgroundStory: char.role
        })),
        scene: story.content.substring(0, 100) + "...",
        narrativeStyle: "third_person",
        explicitLevel: story.explicitLevel || "moderate",
        ageConfirmed: true,
        location: story.location || "",
      };

      const result = await continueStory(
        story.content,
        explicitLevel,
        storyData,
        story.title
      );
      setContinuation(result.continuation);
    } catch (error) {
      console.error("Continue story error:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to continue story",
        variant: "destructive"
      });
    } finally {
      setGeneratingLevel(null);
    }
  };

  const downloadComplete = async (format: 'pdf' | 'txt') => {
    const completeContent = {
      ...story,
      content: `Original Story:\n${story.content}\n\n${continuation ? `Continuation:\n${continuation}` : ''}`
    };
    
    if (format === 'pdf') {
      await downloadStoryAsPDF(completeContent);
    } else {
      await downloadStoryAsTXT(completeContent);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">{story.title}</DialogTitle>
          <div className="flex flex-wrap gap-2 justify-between">
            <div className="flex gap-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => downloadComplete('pdf')}
                    >
                      <FileType className="h-4 w-4 mr-2" />
                      Download PDF
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download complete story as PDF</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => downloadComplete('txt')}
                    >
                      <Download className="h-4 w-4 mr-2" />
                      Download TXT
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download complete story as TXT</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            {isPremium && (
              <div className="flex gap-2">
                <Button
                  variant="premium"
                  size="sm"
                  onClick={() => handleContinue('more')}
                  disabled={generatingLevel !== null}
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${generatingLevel === 'more' ? "animate-spin" : ""}`} />
                  Continue (More)
                </Button>
                <Button
                  variant="premium"
                  size="sm"
                  onClick={() => handleContinue('same')}
                  disabled={generatingLevel !== null}
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${generatingLevel === 'same' ? "animate-spin" : ""}`} />
                  Continue (Same)
                </Button>
                <Button
                  variant="premium"
                  size="sm"
                  onClick={() => handleContinue('less')}
                  disabled={generatingLevel !== null}
                >
                  <RefreshCw className={`h-4 w-4 mr-2 ${generatingLevel === 'less' ? "animate-spin" : ""}`} />
                  Continue (Less)
                </Button>
              </div>
            )}
          </div>
        </DialogHeader>
        <ScrollArea className="h-[500px]">
          <div className="space-y-4 p-4">
            {story.content.split("\n").map((paragraph: string, index: number) => (
              <p key={index} className="text-gray-700">{paragraph}</p>
            ))}
            {continuation && (
              <>
                <div className="my-8 py-4 border-t border-gray-200 relative">
                  <span className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-4 text-sm text-muted-foreground">
                    Story Continuation
                  </span>
                </div>
                {continuation.split("\n").map((paragraph: string, index: number) => (
                  <p key={`cont-${index}`} className="text-gray-700">{paragraph}</p>
                ))}
              </>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
