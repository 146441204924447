import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { CustomDialog } from "./ui/custom-dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "../hooks/use-toast";
import { useAuthStore } from "../store/auth";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "../lib/supabase";
import type { User } from "../types/user";

interface CustomRegisterDialogProps {
  open: boolean;
  onClose: () => void;
  onLoginClick?: () => void;
}

export function CustomRegisterDialog({ open, onClose, onLoginClick }: CustomRegisterDialogProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: ""
  });
  const [loading, setLoading] = useState(false);
  const [isAutofilling, setIsAutofilling] = useState(false);
  const { toast } = useToast();
  const setUser = useAuthStore(state => state.setUser);
  const queryClient = useQueryClient();
  const autofillTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!open || !formRef.current) return;

    const form = formRef.current;
    const inputs = form.querySelectorAll('input');

    const handleValueChange = (input: HTMLInputElement) => {
      setIsAutofilling(true);
      if (autofillTimeoutRef.current) {
        clearTimeout(autofillTimeoutRef.current);
      }

      setFormData(prev => ({
        ...prev,
        [input.name]: input.value
      }));

      autofillTimeoutRef.current = setTimeout(() => {
        setIsAutofilling(false);
      }, 1000);
    };

    const observers = new Map();

    inputs.forEach(input => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          handleValueChange(input as HTMLInputElement);
        });
      });

      observer.observe(input, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true
      });

      observers.set(input, observer);

      input.addEventListener('input', () => handleValueChange(input as HTMLInputElement));
      input.addEventListener('change', () => handleValueChange(input as HTMLInputElement));
    });

    return () => {
      observers.forEach(observer => observer.disconnect());
      if (autofillTimeoutRef.current) {
        clearTimeout(autofillTimeoutRef.current);
      }
    };
  }, [open]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const mapSupabaseUser = async (supabaseUser: any): Promise<User> => {
    return {
      id: supabaseUser.id,
      username: formData.username,
      role: "user",
      ageVerified: false,
      createdAt: supabaseUser.created_at ? new Date(supabaseUser.created_at).getTime() : undefined,
      isPremium: false
    };
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            username: formData.username,
          },
          emailRedirectTo: window.location.origin,
        },
      });

      if (error) throw error;

      if (data.user?.identities?.length === 0) {
        toast({
          title: "Error",
          description: "An account with this email already exists",
          variant: "destructive",
        });
        return;
      }

      const mappedUser = await mapSupabaseUser(data.user);
      setUser(mappedUser);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      onClose();
      toast({ 
        title: "Success", 
        description: "Please check your email to confirm your account" 
      });
    } catch (error) {
      console.error("Registration failed:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Registration failed",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog 
      open={open} 
      onClose={onClose}
      preventClose={loading}
    >
      <div className="flex flex-col items-center justify-center w-full">

        <div className="w-full">
          <div className="p-6 space-y-4 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl">
              Create your account
            </h1>
            <div className="space-y-4 md:space-y-6">
              <form ref={formRef} onSubmit={handleRegister} className="space-y-4 md:space-y-6">
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder=""
                    required
                    autoComplete="email"
                  />
                </div>
                <div>
                  <label htmlFor="username" className="block mb-2 text-sm font-medium text-white">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder=""
                    required
                    autoComplete="username"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="bg-gray-700 border border-gray-600 text-white sm:text-sm rounded-lg focus:ring-blue-500 focus:border-red-500 block w-full p-2.5"
                    placeholder=""
                    required
                    autoComplete="new-password"
                  />
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? "Creating account..." : "Create account"}
                </button>
                <p className="text-sm font-light text-gray-400">
                  Already have an account?{" "}
                  <button
                    type="button"
                    onClick={onLoginClick}
                    className="font-medium text-gray-200 hover:underline"
                    >
                    Sign in
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
