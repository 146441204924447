import React from 'react';
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Progress } from "./ui/progress";
import { Button } from "./ui/button";
import { useLocation } from "wouter";
import { Card } from "./ui/card";

interface GenerationLimitsProps {
  remainingGenerations: number;
  maxGenerations: number;
  isLoggedIn: boolean;
  setShowRegister: (show: boolean) => void;
}

export function GenerationLimits({ remainingGenerations, maxGenerations, isLoggedIn, setShowRegister }: GenerationLimitsProps) {
  const [, setLocation] = useLocation();
  const usedGenerations = maxGenerations - remainingGenerations;
  const progressPercentage = (usedGenerations / maxGenerations) * 100;

  if (isLoggedIn) return null;

  return (
    <Card className="p-6 my-6 bg-gradient-to-r from-gray-900 to-gray-800 border-gray-700">
      <div className="space-y-6">
        {/* Usage Status */}
        <div>
          <h3 className="text-xl font-semibold mb-4 text-white">Free Account Status</h3>
          <div className="flex justify-between text-base mb-2 text-gray-200">
            <span>Story Generations Used: {usedGenerations}/{maxGenerations}</span>
            <span className={remainingGenerations === 0 ? "text-red-500" : "text-green-500"}>
              {remainingGenerations} remaining
            </span>
          </div>
          <Progress 
            value={progressPercentage} 
            className="h-2"
            // Add color based on remaining generations
            style={{
              background: 'rgba(255, 255, 255, 0.1)',
              '--progress-background': remainingGenerations === 0 ? '#ef4444' : '#22c55e'
            } as React.CSSProperties}
          />
        </div>

        {/* Plan Comparison */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Free Plan */}
          <div className="space-y-3">
            <h4 className="font-medium text-gray-300">Free Plan Features:</h4>
            <ul className="space-y-2 text-sm text-gray-400">
              <li>• {maxGenerations} stories per month</li>
              <li>• Stories up to 1,500 words</li>
              <li>• Basic story exports (PDF/TXT)</li>
            </ul>
          </div>

          {/* Premium Benefits */}
          <div className="space-y-3">
            <h4 className="font-bold text-gray-300">Upgrade to Premium:</h4>
            <ul className="space-y-2 text-sm text-gray-300">
              <li className="flex items-center font-bold">
                <span className="text-amber-400 mr-2">✨</span>
                Unlimited story generations
              </li>
              <li className="flex items-center font-bold">
                <span className="text-amber-400 mr-2">✨</span>
                Save & continue writing your stories
              </li>
              <li className="flex items-center font-bold">
                <span className="text-amber-400 mr-2">✨</span>
                Extended story length (up to 5,000 words)
              </li>
            </ul>
          </div>
        </div>

        {/* CTA Button */}
        <Button 
          onClick={() => setShowRegister(true)}
          variant="default"
          className="w-full bg-gradient-to-r from-gray-500 to-gray-600 hover:from-gray-600 hover:to-gray-700 text-white shadow-lg"
        >
          {remainingGenerations === 0 
            ? "Upgrade Now - Your Free Generations Are Used" 
            : "Upgrade to Premium - Unlock All Features"}
        </Button>
      </div>
    </Card>
  );
}
