import { Button } from "../../components/ui/button";
import { useLocation } from "wouter";
import { CustomRegisterDialog } from "../../components/CustomRegisterDialog";
import { LoginDialog } from "../../components/LoginDialog";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { User, Shield, Menu, X } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "../../components/ui/avatar";
import { useAuthStore } from "../../store/auth";
import { supabase } from "../../lib/supabase";

export function Header() {
  if (typeof window === 'undefined') {
    return null;
  }

  const [, setLocation] = useLocation();
  const queryClient = useQueryClient();
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, setUser } = useAuthStore();

  // Initial auth check can still use React Query
  useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await fetch("/api/auth/me");
      if (!response.ok) return null;
      const userData = await response.json();
      setUser(userData); // Update Zustand store
      return userData;
    },
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      queryClient.clear();
      setUser(null);
      setLocation("/");
    }
  };

  return (
    <header className="border-b border-gray-800 bg-black sticky top-0 z-50 w-full">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex items-center cursor-pointer" onClick={() => setLocation("/")}>
          <img
            src="/logo.svg"
            alt="StoryCraft Logo"
            className="w-9 h-9 sm:w-12 sm:h-12 mr-2 sm:mr-4 object-contain"
          />
          <span className="text-lg sm:text-xl font-bold text-style-bold font-serif text-white">
            So Naughty
          </span>
        </div>
        
        {/* Mobile menu button */}
        <Button
          variant="ghost"
          size="icon"
          className="lg:hidden text-white"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </Button>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center space-x-4">
          <nav className="flex items-center space-x-4 mr-4">
            <Button 
              variant="ghost" 
              onClick={() => setLocation("/")}
              className="text-white hover:text-gray-300 hover:bg-gray-900 text-sm sm:text-md"
            >
              Home
            </Button>
            <Button 
              variant="ghost" 
              onClick={() => setLocation("/create")}
              className="text-white hover:text-gray-300 hover:bg-gray-900 text-sm sm:text-md"
            >
              Create Story
            </Button>
          </nav>
          {user ? (
            <div className="flex items-center space-x-3">
              <Button
                variant="ghost"
                size="icon"
                className="rounded-full text-red-600 hover:text-red-600 hover:bg-gray-800"
                onClick={() => setLocation("/profile")}
              >
                <Avatar>
                  <AvatarFallback>
                    <User className="h-5 w-5" />
                  </AvatarFallback>
                </Avatar>
              </Button>
              <Button 
                variant="outline" 
                onClick={handleLogout}
                className="text-sm sm:text-md"
              >
                Logout
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              onClick={() => setShowLogin(true)}
              className="text-sm sm:text-md"
            >
              Login / Sign Up
            </Button>
          )}
        </div>

        {/* Mobile Navigation */}
        {mobileMenuOpen && (
          <div className="lg:hidden absolute top-full left-0 right-0 bg-black border-b border-gray-800 py-4">
            <nav className="flex flex-col space-y-2 px-4">
              <Button 
                variant="ghost" 
                onClick={() => {
                  setLocation("/");
                  setMobileMenuOpen(false);
                }}
                className="text-white hover:text-gray-300 hover:bg-gray-900 text-left justify-start"
              >
                Home
              </Button>
              <Button 
                variant="ghost" 
                onClick={() => {
                  setLocation("/create");
                  setMobileMenuOpen(false);
                }}
                className="text-white hover:text-gray-300 hover:bg-gray-900 text-left justify-start"
              >
                Create Story
              </Button>
              {user ? (
                <>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setLocation("/profile");
                      setMobileMenuOpen(false);
                    }}
                    className="text-white hover:text-gray-300 hover:bg-gray-900 text-left justify-start"
                  >
                    Profile
                  </Button>
                  <Button 
                    variant="outline" 
                    onClick={() => {
                      handleLogout();
                      setMobileMenuOpen(false);
                    }}
                    className="text-left justify-start"
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowLogin(true);
                    setMobileMenuOpen(false);
                  }}
                >
                  Login / Sign Up
                </Button>
              )}
            </nav>
          </div>
        )}
      </div>
      <CustomRegisterDialog 
        open={showRegister} 
        onClose={() => setShowRegister(false)}
        onLoginClick={() => {
          setShowRegister(false);
          setShowLogin(true);
        }}
      />
      <LoginDialog
        open={showLogin}
        onClose={() => setShowLogin(false)}
        onRegisterClick={() => {
          setShowLogin(false);
          setShowRegister(true);
        }}
      />
    </header>
  );
}
