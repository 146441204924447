import jsPDF from 'jspdf';
import { toast } from "../hooks/use-toast";
import type { Story } from "@/../../types/schema";

export const downloadStoryAsPDF = (story: Story) => {
  try {
    const pdf = new jsPDF();
    
    // Add title
    pdf.setFontSize(16);
    pdf.text(story.title, 20, 20);
    
    // Add story content
    pdf.setFontSize(12);
    const contentLines = pdf.splitTextToSize(story.content, 170);
    pdf.text(contentLines, 20, 35);
    
    // Save the PDF
    pdf.save(`${story.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${Date.now()}.pdf`);
    
    toast({
      title: "Success",
      description: "Story downloaded as PDF"
    });
  } catch (error) {
    console.error("PDF download error:", error);
    toast({
      title: "Error",
      description: "Failed to download PDF",
      variant: "destructive"
    });
  }
};

export const downloadStoryAsTXT = (story: Story) => {
  try {
    const content = `${story.title}

${story.content}`;
    
    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${story.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${Date.now()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    
    toast({
      title: "Success",
      description: "Story downloaded as TXT"
    });
  } catch (error) {
    console.error("TXT download error:", error);
    toast({
      title: "Error",
      description: "Failed to download TXT",
      variant: "destructive"
    });
  }
};
