import React from 'react';
import { Link } from 'wouter';
import { Header } from '@/components/app/Header';
import Footer from '@/components/app/Footer';
import { SEO } from '@/components/SEO';

const ContentPolicy = () => {
  return (
    <>
      <SEO 
        title="Content Policy"
        description="StoryCraft's content policy guidelines. Learn about our community standards, content moderation, and acceptable use policies."
        keywords="StoryCraft content policy, community guidelines, content moderation, acceptable use"
      />
      <div className="min-h-screen bg-black text-white">
        <Header />
        <main className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-serif mb-8">Content Policy</h1>
            
            <section className="space-y-6">
              <div>
                <h2 className="text-2xl font-serif mb-4">1. Acceptable Content</h2>
                <p className="text-gray-300">
                  Our platform is designed for creative storytelling. We encourage original, imaginative content while maintaining community standards.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">2. Prohibited Content</h2>
                <ul className="list-disc pl-6 text-gray-300 space-y-2">
                  <li>Content that promotes hate speech or discrimination</li>
                  <li>Explicit or graphic violence</li>
                  <li>Content that infringes on intellectual property rights</li>
                  <li>Spam or malicious content</li>
                  <li>Content that violates local laws</li>
                </ul>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">3. Content Moderation</h2>
                <p className="text-gray-300">
                  We actively moderate content to maintain a safe and creative environment. Content that violates our policies may be removed.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">4. User Rights</h2>
                <p className="text-gray-300">
                  Users retain rights to their original content. By posting on our platform, you grant us a license to display and distribute your content.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">5. Reporting Content</h2>
                <p className="text-gray-300">
                  If you encounter content that violates our policies, please report it. We review all reports and take appropriate action.
                </p>
              </div>

              <div>
                <h2 className="text-2xl font-serif mb-4">6. Updates to Policy</h2>
                <p className="text-gray-300">
                  This content policy may be updated periodically. Users will be notified of significant changes.
                </p>
              </div>
            </section>
            
            <div className="mt-8">
              <Link href="/">
                <a className="text-red-500 hover:text-red-400">← Back to Home</a>
              </Link>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ContentPolicy;
