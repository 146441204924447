import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from "react";
import { useQuery, useMutation, useQueryClient, QueryClient } from "@tanstack/react-query";
import { useLocation } from "wouter";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { generateStory, getUserStories, continueStory, deleteStory } from "../../lib/api";
import type { Story, StoryGeneration } from "../../../types/schema";
import { useToast } from "../../hooks/use-toast";
import { Header } from "./Header";
import Footer from "./Footer";
import { useAuthStore } from "../../store/auth";
import { FeatureDisplay } from "../features/FeatureDisplay";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Avatar, AvatarFallback } from "../ui/avatar";
import { StoryDialog } from "./StoryDialog";
import { DeleteConfirmDialog } from "./DeleteConfirmDialog";
import { Trash2, Eye } from 'lucide-react';
import { SubscriptionManager } from '../premium/SubscriptionManager';
import { PaymentHistory } from '../premium/PaymentHistory';

export default function Profile() {
  const user = useAuthStore((state) => state.user);
  const token = useAuthStore((state) => state.token);
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("stories");
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [storyToDelete, setStoryToDelete] = useState<Story | null>(null);
  const { hasFeature } = useFeatureFlag();
  const queryClient = useQueryClient();
  const [, setLocation] = useLocation();

  // Fetch user's stories
  const { data: stories = [], isLoading: isLoadingStories } = useQuery({
    queryKey: ['stories', user?.id, token],
    queryFn: getUserStories,
    enabled: !!user && !!token,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: 2
  });

  // Story generation mutation
  const generateMutation = useMutation({
    mutationFn: (data: StoryGeneration) => generateStory(data),
    onSuccess: (data) => {
      toast({
        title: "Story Generated!",
        description: data.message || "Your story has been created successfully.",
      });
      queryClient.invalidateQueries({ queryKey: ['stories'] });
    },
    onError: (error: Error) => {
      toast({
        title: "Generation Failed",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  // Story deletion mutation
  const deleteMutation = useMutation({
    mutationFn: (id: string | number) => deleteStory(id),
    onSuccess: () => {
      toast({
        title: "Story Deleted",
        description: "Your story has been permanently deleted.",
      });
      queryClient.invalidateQueries({ queryKey: ['stories'] });
      setStoryToDelete(null);
    },
    onError: (error: Error) => {
      toast({
        title: "Deletion Failed",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const handleGenerateStory = () => {
    setLocation('/create');
  };

  const handleContinueStory = async () => {
    if (!selectedStory) {
      toast({
        title: "No Story Selected",
        description: "Please select a story to continue",
      });
      return;
    }

    if (!hasFeature('STORY_CONTINUATION')) {
      toast({
        title: "Premium Feature",
        description: "Upgrade to premium to continue stories!",
        variant: "default",
      });
      return;
    }

    // Story continuation logic is handled in StoryDialog component
    setSelectedStory(selectedStory);
  };

  const handleExportStory = async () => {
    if (!selectedStory) {
      toast({
        title: "No Story Selected",
        description: "Please select a story to export",
      });
      return;
    }

    if (!hasFeature('STORY_EXPORT')) {
      toast({
        title: "Feature Locked",
        description: "This feature is not available on your current plan",
      });
      return;
    }

    // Export logic from StoryDialog will be used
  };

  const handleSaveStory = async () => {
    if (!hasFeature('STORY_SAVING')) {
      toast({
        title: "Feature Locked",
        description: "Log in to save your stories!",
      });
      return;
    }

    // Story saving is handled automatically after generation
    toast({
      title: "Story Saved",
      description: "Your story has been saved to your library",
    });
  };

  const handleDeleteStory = (story: Story, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent story selection when clicking delete
    setStoryToDelete(story);
  };

  const confirmDelete = async () => {
    if (storyToDelete) {
      await deleteMutation.mutateAsync(storyToDelete.id);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col bg-black text-white">
      <Helmet>
        <title>Profile - Naughty.so</title>
      </Helmet>
      <Header />
      <main className="container mx-auto px-4 py-6">
        <Card className="w-full bg-gray-800">
          <CardHeader>
            <div className="flex items-center space-x-4">
              <Avatar>
                <AvatarFallback>{user?.username?.charAt(0).toUpperCase()}</AvatarFallback>
              </Avatar>
              <div>
                <CardTitle className="text-2xl font-bold text-gray-100">{user?.username}</CardTitle>
                <CardDescription className="text-gray-300">Member since {new Date(user?.createdAt || Date.now()).toLocaleDateString()}</CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
              <TabsList className="w-full bg-gray-700">
                <TabsTrigger value="stories" className="flex-1 text-gray-200 data-[state=active]:bg-gray-600">Stories</TabsTrigger>
                <TabsTrigger value="subscription" className="flex-1 text-gray-200 data-[state=active]:bg-gray-600">Premium Status</TabsTrigger>
                <TabsTrigger value="payments" className="flex-1 text-gray-200 data-[state=active]:bg-gray-600">Billing</TabsTrigger>
              </TabsList>

              <TabsContent value="stories">
                <Card className="bg-gray-800">
                  <CardHeader>
                    <CardTitle className="text-gray-100">My Stories</CardTitle>
                    <CardDescription className="text-gray-300">
                      Your saved stories
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    {isLoadingStories ? (
                      <div className="text-center text-muted-foreground py-8">
                        Loading stories...
                      </div>
                    ) : stories.length === 0 ? (
                      <div className="text-center text-muted-foreground py-8">
                        No stories yet. Start by generating one!
                      </div>
                    ) : (
                      <ScrollArea className="h-[400px]">
                        <div className="space-y-4">
                          {stories.map((story) => (
                            <Card
                              key={story.id}
                              className="cursor-pointer hover:bg-accent group relative"
                            >
                              <CardHeader>
                                <CardTitle>{story.title}</CardTitle>
                                <CardDescription>
                                  {story.content.substring(0, 100)}...
                                </CardDescription>
                                <div className="absolute right-4 top-4 opacity-0 group-hover:opacity-100 transition-opacity">
                                  <div className="flex gap-2">
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedStory(story);
                                      }}
                                    >
                                      <Eye className="h-4 w-4" />
                                    </Button>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      className="text-destructive hover:text-destructive hover:bg-destructive/10"
                                      onClick={(e) => handleDeleteStory(story, e)}
                                    >
                                      <Trash2 className="h-4 w-4" />
                                    </Button>
                                  </div>
                                </div>
                              </CardHeader>
                            </Card>
                          ))}
                        </div>
                      </ScrollArea>
                    )}
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="subscription">
                <Card className="bg-gray-800">
                  <CardHeader>
                    <CardTitle className="text-gray-100">Premium Features</CardTitle>
                    <CardDescription className="text-gray-300">
                      Discover what you can do with Naughty.so
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FeatureDisplay />
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="payments">
                <Card className="bg-gray-800">
                  <CardHeader>
                    <CardTitle className="text-gray-100">Payment History</CardTitle>
                    <CardDescription className="text-gray-300">
                      Your payment history
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <PaymentHistory />
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </main>
      <Footer />
      {selectedStory && (
        <StoryDialog
          story={selectedStory}
          open={!!selectedStory}
          onClose={() => setSelectedStory(null)}
          isPremium={hasFeature('STORY_CONTINUATION')}
        />
      )}
      {storyToDelete && (
        <DeleteConfirmDialog
          open={!!storyToDelete}
          onClose={() => setStoryToDelete(null)}
          onConfirm={() => deleteMutation.mutate(storyToDelete.id)}
          title="Delete Story"
        />
      )}
    </div>
  );
}
