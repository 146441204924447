import React from 'react';
import { Link } from 'wouter';
import { ChevronLeft } from 'lucide-react';
import { Button } from "@/components/ui/button";
import Footer from '@/components/app/Footer';
import { Header } from '@/components/app/Header';

const CommunityGuidelinesPage = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <main className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="mb-8">
          <Link href="/support">
            <a className="inline-flex items-center text-gray-400 hover:text-white transition-colors">
              <ChevronLeft className="w-4 h-4 mr-1" />
              Back to Support
            </a>
          </Link>
        </div>

        <h1 className="text-4xl font-serif mb-8">Community Guidelines</h1>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-serif mb-4">Being a Good Community Member</h2>
            <p className="text-gray-300 mb-4">
              Our community thrives when everyone participates positively. Here's how you can contribute:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Be respectful and constructive in comments</li>
              <li>Acknowledge and appreciate others' work</li>
              <li>Report inappropriate content</li>
              <li>Help newcomers feel welcome</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Content Standards</h2>
            <p className="text-gray-300 mb-4">
              When creating and sharing content, please follow these guidelines:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Avoid explicit or offensive content</li>
              <li>Respect intellectual property rights</li>
              <li>Tag content appropriately for its audience</li>
              <li>Don't spam or post misleading content</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-serif mb-4">Reporting and Moderation</h2>
            <p className="text-gray-300 mb-4">
              Help us maintain a healthy community:
            </p>
            <ul className="list-disc pl-6 text-gray-300 space-y-2">
              <li>Use the report button for violations</li>
              <li>Provide context when reporting</li>
              <li>Block users who harass or spam</li>
              <li>Contact moderators for serious issues</li>
            </ul>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CommunityGuidelinesPage;
