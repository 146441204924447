import type { Story } from '../../types/schema';

export async function getRecentStories(): Promise<Story[]> {
  try {
    const response = await fetch('/api/stories');
    
    if (!response.ok) {
      const error = await response.json().catch(() => ({ error: 'Failed to parse error response' }));
      console.error('Failed to fetch recent stories:', error);
      throw new Error(error.error || 'Failed to fetch stories');
    }
    
    const data = await response.json();
    
    if (!Array.isArray(data)) {
      console.error('Invalid response format:', data);
      return [];
    }
    
    // Validate each story has required fields
    return data.filter((story: any) => {
      const isValid = story && 
        typeof story.id === 'number' && 
        typeof story.title === 'string' && 
        typeof story.content === 'string' &&
        typeof story.genre === 'string';
        
      if (!isValid) {
        console.warn('Invalid story format:', story);
      }
      return isValid;
    });
  } catch (error) {
    console.error('Error fetching recent stories:', error);
    return [];
  }
}
