import { Label } from "../components/ui/label";
import { Checkbox } from "../components/ui/checkbox";

interface SexActsSelectionProps {
  value: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
}

const sexActOptions = [
  { id: "69", label: "69" },
  { id: "anal", label: "Anal" },
  { id: "anal_orgasm", label: "Anal Orgasm" },
  { id: "balls_in_mouth", label: "Balls in Mouth" },
  { id: "bareback", label: "Bareback" },
  { id: "bite_nipples", label: "Bite Nipples" },
  { id: "blindfold", label: "Blindfold" },
  { id: "bondage", label: "Bondage" },
  { id: "breeding", label: "Breeding" },
  { id: "cum_in_ass", label: "Cum in Ass" },
  { id: "cum_in_mouth", label: "Cum in Mouth" },
  { id: "cum_in_pussy", label: "Cum in Pussy" },
  { id: "cum_on_face", label: "Cum on Face" },
  { id: "deep_kiss", label: "Deep Kiss" },
  { id: "deep_throat", label: "Deep Throat" },
  { id: "dirty_talk", label: "Dirty Talk" },
  { id: "doggystyle_position", label: "Doggystyle Position" },
  { id: "double_penetrate", label: "Double Penetrate" },
  { id: "double_penetrate_ass", label: "Double Penetrate Ass" },
  { id: "double_penetrate_ass_and_pussy", label: "Double Penetrate: Ass & Pussy" },
  { id: "double_penetrate_pussy", label: "Double Penetrate Pussy" },
  { id: "drink_cum", label: "Drink Cum" },
  { id: "drink_milk", label: "Drink Milk" },
  { id: "eat_ass", label: "Eat Ass" },
  { id: "edging", label: "Edging" },
  { id: "face_in_ass", label: "Face in Ass" },
  { id: "face_sitting", label: "Face Sitting" },
  { id: "fingering", label: "Fingering" },
  { id: "finger_ass", label: "Finger Ass" },
  { id: "finger_pussy", label: "Finger Pussy" },
  { id: "fist_ass", label: "Fist Ass" },
  { id: "fist_pussy", label: "Fist Pussy" },
  { id: "frottage", label: "Frottage" },
  { id: "fuck_face", label: "Fuck Face" },
  { id: "fuck_pussy", label: "Fuck Pussy" },
  { id: "fuck_throat", label: "Fuck Throat" },
  { id: "fuck_while_fucked_in_ass", label: "Fuck while Fucked in Ass" },
  { id: "handjob", label: "Handjob" },
  { id: "inseminate", label: "Inseminate" },
  { id: "lick_armpits", label: "Lick Armpits" },
  { id: "lick_earlobes", label: "Lick Earlobes" },
  { id: "lick_neck", label: "Lick Neck" },
  { id: "lick_nipples", label: "Lick Nipples" },
  { id: "lick_perineum", label: "Lick Perineum" },
  { id: "lick_pussy", label: "Lick Pussy" },
  { id: "lick_sweat", label: "Lick Sweat" },
  { id: "lick_toes", label: "Lick Toes" },
  { id: "lick_all_over_body", label: "Lick All Over Body" },
  { id: "masturbation", label: "Masturbation" },
  { id: "massage", label: "Massage" },
  { id: "missionary_position", label: "Missionary Position" },
  { id: "mutual_masturbation", label: "Mutual Masturbation" },
  { id: "oral", label: "Oral" },
  { id: "pegging_ass_with_dildo", label: "Pegging Ass with Dildo" },
  { id: "penetration", label: "Penetration" },
  { id: "pinch_nipples", label: "Pinch Nipples" },
  { id: "prostate_orgasm", label: "Prostate Orgasm" },
  { id: "restraints", label: "Restraints" },
  { id: "ride_cock", label: "Ride Cock" },
  { id: "rimming_analingus", label: "Rimming (Analingus)" },
  { id: "rub_asshole", label: "Rub Asshole" },
  { id: "rub_clit", label: "Rub Clit" },
  { id: "rub_pussy", label: "Rub Pussy" },
  { id: "rub_pussies_together", label: "Rub Pussies Together" },
  { id: "rub_nipples_together", label: "Rub Nipples Together" },
  { id: "rub_cocks_together", label: "Rub Cocks Together" },
  { id: "scissoring", label: "Scissoring" },
  { id: "sit_on_cock", label: "Sit on Cock" },
  { id: "smell_armpits", label: "Smell Armpits" },
  { id: "spank_ass", label: "Spank Ass" },
  { id: "spread_legs", label: "Spread Legs" },
  { id: "spit_roast", label: "Spit Roast" },
  { id: "squirt_due_to_anal", label: "Squirt due to Anal" },
  { id: "squirt_from_pussy", label: "Squirt from Pussy" },
  { id: "squirt_on_face", label: "Squirt on Face" },
  { id: "suck_ass", label: "Suck Ass" },
  { id: "suck_cock", label: "Suck Cock" },
  { id: "suck_cock_and_finger_ass", label: "Suck Cock & Finger Ass" },
  { id: "suck_cock_together", label: "Suck Cock Together" },
  { id: "suck_hard_on_nipple", label: "Suck Hard on Nipple" },
  { id: "suck_hard_on_clit", label: "Suck Hard on Clit" },
  { id: "suck_milk", label: "Suck Milk" },
  { id: "swallow_cum", label: "Swallow Cum" },
  { id: "tongue_in_asshole", label: "Tongue in Asshole" },
  { id: "tongue_in_pussy", label: "Tongue in Pussy" },
  { id: "touching", label: "Touching" },
  { id: "toys", label: "Toys" },
  { id: "tribadism", label: "Tribadism" },
]

export default function SexActsSelection({
  value,
  onChange,
  disabled = false,
}: SexActsSelectionProps) {
  const handleToggle = (actId: string) => {
    if (value.includes(actId)) {
      onChange(value.filter((id) => id !== actId));
    } else {
      onChange([...value, actId]);
    }
  };

  // Split options into three columns
  const columnSize = Math.ceil(sexActOptions.length / 3);
  const firstColumn = sexActOptions.slice(0, columnSize);
  const secondColumn = sexActOptions.slice(columnSize, columnSize * 2);
  const thirdColumn = sexActOptions.slice(columnSize * 2);

  return (
    <div className="space-y-4">
      <Label htmlFor="location" className="text-white">
            Sexual Acts
          </Label>
      <div className="grid grid-cols-3 gap-x-4 gap-y-2 border rounded-lg p-4">
        <div className="space-y-2">
          {firstColumn.map((act) => (
            <div key={act.id} className="flex items-center space-x-2">
              <Checkbox
                id={act.id}
                checked={value.includes(act.id)}
                onCheckedChange={() => handleToggle(act.id)}
                disabled={disabled}
              />
              <label
                htmlFor={act.id}
                className="text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {act.label}
              </label>
            </div>
          ))}
        </div>
        <div className="space-y-2">
          {secondColumn.map((act) => (
            <div key={act.id} className="flex items-center space-x-2">
              <Checkbox
                id={act.id}
                checked={value.includes(act.id)}
                onCheckedChange={() => handleToggle(act.id)}
                disabled={disabled}
              />
              <label
                htmlFor={act.id}
                className="text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {act.label}
              </label>
            </div>
          ))}
        </div>
        <div className="space-y-2">
          {thirdColumn.map((act) => (
            <div key={act.id} className="flex items-center space-x-2">
              <Checkbox
                id={act.id}
                checked={value.includes(act.id)}
                onCheckedChange={() => handleToggle(act.id)}
                disabled={disabled}
              />
              <label
                htmlFor={act.id}
                className="text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {act.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
